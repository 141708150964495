import { AppBar, Typography, Dialog, DialogActions, DialogContent, IconButton, Toolbar, Stack } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'

interface FullscreenDialogProps {
    toolbarTitle: string
    toolbarStartIcon?: ReactNode
    toolbarEndIcon?: ReactNode
    dialogContent: ReactNode
    dialogActions?: ReactNode
    dialogContentStyle?: object
    transitionComponent?: any
    open?: boolean
    handleClose?: () => void
}

export const FullscreenDialog = ({
    toolbarTitle,
    toolbarStartIcon,
    toolbarEndIcon,
    dialogContent,
    dialogActions,
    dialogContentStyle = { p: 2 },
    transitionComponent,
    open = true,
    handleClose,
}: FullscreenDialogProps) => {
    const navigate = useNavigate()

    const handleDialogClose = () => {
        if (handleClose) {
            handleClose()
        } else {
            navigate(-1)
        }
    }

    return (
        <Dialog fullScreen open={open} onClose={handleDialogClose} TransitionComponent={transitionComponent}>
            <Stack direction='row' justifyContent='space-between'>
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar>
                        {toolbarStartIcon}
                        <Typography sx={{ ml: 2, flex: 1 }} variant='h6' component='div'>
                            {toolbarTitle}
                        </Typography>
                        {toolbarEndIcon}
                        <IconButton color='inherit' onClick={handleDialogClose} aria-label='close' size='large'>
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
            </Stack>
            <DialogContent sx={dialogContentStyle}>{dialogContent}</DialogContent>
            {dialogActions && <DialogActions>{dialogActions}</DialogActions>}
        </Dialog>
    )
}
