import { createRoot } from 'react-dom/client'
export { LocationPin, createPUPMarker }

const LocationPinIcon = ({
    disabled = false,
    scale = 1,
    translateY,
}: {
    disabled: boolean
    scale?: number
    translateY: number // Added so we can hide the annoying cross on GMap
}) => (
    <svg
        xmlns='http://www.w3.org/2000/svg'
        width={24}
        height={24}
        viewBox='0 0 600 600'
        xmlSpace='preserve'
        style={{ transform: `scale(${scale}) translateY(${translateY}px)`, transformOrigin: 'center' }}
    >
        <g>
            <path
                stroke='#FFF'
                strokeWidth='10'
                fill={'#FFF'}
                d='M302.851 585.358c-71.684 0-139.6-9.602-191.238-27.039C30.108 530.795 13 492.215 13 464.698c0-27.518 17.108-66.098 98.613-93.62 51.637-17.437 119.554-27.04 191.238-27.04s139.601 9.603 191.238 27.04c81.504 27.523 98.612 66.103 98.612 93.62s-17.108 66.097-98.612 93.62c-51.639 17.437-119.555 27.04-191.238 27.04M86.557 464.698c2.914 4.263 15.521 15.946 49.29 27.349 44.053 14.875 103.363 23.067 167.004 23.067s122.95-8.192 167.004-23.067c33.769-11.404 46.375-23.087 49.29-27.349-2.914-4.263-15.521-15.947-49.29-27.35-44.054-14.875-103.364-23.067-167.004-23.067-63.641 0-122.951 8.192-167.004 23.067-33.769 11.404-46.375 23.088-49.29 27.35'
            />
            <path
                fill='#1D1D1B'
                d='M302.851 570.903c-70.154 0-136.427-9.333-186.614-26.28-73.379-24.779-88.782-57.14-88.782-79.925s15.403-55.147 88.782-79.925c50.187-16.947 116.461-26.28 186.614-26.28s136.427 9.333 186.614 26.28c73.379 24.779 88.782 57.14 88.782 79.925s-15.403 55.147-88.782 79.925c-50.187 16.947-116.461 26.28-186.614 26.28m0-171.076c-65.174 0-126.125 8.462-171.628 23.827-41.176 13.905-59.607 30.634-59.607 41.045s18.431 27.14 59.607 41.045c45.503 15.365 106.454 23.827 171.628 23.827s126.125-8.462 171.628-23.827c41.176-13.905 59.607-30.634 59.607-41.045s-18.431-27.14-59.607-41.045c-45.504-15.365-106.455-23.827-171.628-23.827'
            />
            <path
                stroke='#FFF'
                strokeWidth='10'
                fill='#FFF'
                d='M430.089 66.89c-33.688-34.876-78.165-54.366-125.237-54.88-.624-.007-1.262-.01-1.885-.01-47.955 0-93.254 19.731-127.553 55.558-34.915 36.471-53.712 85.567-51.569 134.681l.059 1.291.031.37c1.23 14.543 4.308 29.153 9.154 43.454.242 1.153.564 2.29.964 3.402 12.739 35.419 37.306 90.963 68.05 140.628 36.68 59.252 68.67 88.299 97.797 88.801l3.075.003.167-.003c15.344-.264 31.665-9.271 49.898-27.534 13.403-13.426 28.061-32.037 43.564-55.315 30.562-45.889 55.773-96.54 68.169-126.177 11.522-24.608 17.325-50.69 17.247-77.531-.134-47.199-18.576-92.209-51.931-126.738m17.061 196.457c-27.577 66.124-101.874 196.838-144.34 197.57h-2.578c-48.379-.834-120.251-139.408-148.044-216.683a6.4 6.4 0 0 1-.354-1.633c-4.571-13.113-7.541-26.682-8.694-40.324l-.04-.859c-3.915-89.761 69.77-171.136 161.543-170.136 87.002.949 157.858 75.578 158.106 162.402.07 24.58-5.53 48.287-15.599 69.663'
            />
            <g fill='#FFF'>
                <circle cx={298} cy={200} r={107} />
                <path d='M531 465c0-37.003-108.05-67-229-67S78 427.997 78 465s103.05 67 224 67 229-29.997 229-67' />
            </g>
        </g>
        <ellipse fill='#FFF' cx={311} cy={205} rx={123} ry={110} />
        <path
            fill='#1D1D1B'
            d='m398.75 237.507-79.86 102.006c-10.292 13.146-30.244 12.982-40.317-.333L201.65 237.507h-45.449c-3.531 0-5.206 3.405-4.011 6.727 27.793 77.275 99.666 215.849 148.044 216.683h2.578c49.558-.854 142.47-178.737 153.884-224.392 1.927-7.709-4.007-8.116-6.668-8.559a5 5 0 0 0-1.778.028z'
        />
        <path
            fill='#1D1D1B'
            d='M304.643 31.282C212.87 30.281 139.185 111.657 143.1 201.418l.04.859c6.685 79.053 74.317 155.711 161.503 154.752 88.324-.972 158.353-76.765 158.106-163.344-.248-86.825-71.104-161.454-158.106-162.403m-1.008 264.655c-53.154 0-96.238-43.084-96.238-96.238s43.084-96.238 96.238-96.238 96.238 43.084 96.238 96.238-43.083 96.238-96.238 96.238'
        />
        <circle cx={303.635} cy={199.699} r={57.571} fill='#1D1D1B' />
        {disabled && (
            <>
                <path fill='#1D1D1B' d='M563.939 35.983 532.093 3.985 14.997 527.572l30.944 31.084z' />
                <path fill='#FFF' d='m591.056 63.362-27.117-27.379L45.812 558.476l26.22 26.466z' />
            </>
        )}
    </svg>
)

const createPUPMarker = (
    map: google.maps.Map,
    position: google.maps.LatLngLiteral,
    draggable: boolean,
): [google.maps.marker.AdvancedMarkerElement, HTMLDivElement] => {
    const pinElement = document.createElement('div')
    pinElement.style.cursor = draggable ? 'grab' : 'pointer'

    const root = createRoot(pinElement)
    root.render(<LocationPinIcon disabled={false} scale={1.2} translateY={5} />)

    const pickUpPointMarker = new google.maps.marker.AdvancedMarkerElement({
        position,
        map,
        content: pinElement,
        gmpDraggable: draggable,
        title: draggable ? 'Drag me!' : undefined,
    })

    return [pickUpPointMarker, pinElement]
}

const LocationPin = ({ disabled, scale }: { disabled: boolean; scale: number }) => (
    <LocationPinIcon disabled={disabled} scale={scale} translateY={0} />
)
