import React, { useState, useEffect } from 'react'
import { ToggleButton, ToggleButtonGroup } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useChangeLanguage } from '../i18n/i18n'

export const LanguageSelector = ({ onChange }: { onChange?: () => void }) => {
    const { i18n } = useTranslation()
    const changeLanguage = useChangeLanguage()
    const [selectedLanguage, setSelectedLanguage] = useState(i18n.language)
    const [isInitializing, setIsInitializing] = useState(true)
    const languages = {
        nl: '🇳🇱 Dutch',
        en: '🇬🇧 English',
    }

    useEffect(() => {
        if (isInitializing) {
            setSelectedLanguage(selectedLanguage)
            setIsInitializing(false)
        }
        if (selectedLanguage !== i18n.language) {
            changeLanguage(selectedLanguage)
        }
    }, [selectedLanguage, i18n, isInitializing])

    const handleLanguageChange = (_: React.MouseEvent<HTMLElement>, newLanguage: string) => {
        if (!newLanguage || newLanguage === selectedLanguage) {
            return // Prevent deselecting the current language
        }

        localStorage.setItem('i18nextLng', newLanguage)
        setSelectedLanguage(newLanguage)

        // To close the user menu
        if (onChange) {
            onChange()
        }
    }

    if (isInitializing) return null
    return (
        <ToggleButtonGroup
            value={selectedLanguage}
            exclusive
            onChange={handleLanguageChange}
            aria-label='Language selector'
            size='small'
        >
            {Object.entries(languages).map(([langCode, flag]) => (
                <ToggleButton key={langCode} value={langCode} aria-label={langCode}>
                    {flag}
                </ToggleButton>
            ))}
        </ToggleButtonGroup>
    )
}
