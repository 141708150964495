// 🔴🗓️👤🏷️💬
import config from './config.json'
export const styles = {
    App: {
        MainBox: {
            height: '100%',
        },
    },
    DesktopView: {
        BoxMap: {
            height: '100%',
            flex: 'auto',
            position: 'relative',
            display: 'flex',
            alignItems: 'flex-end',
            flexDirection: 'row-reverse',
        },
        BoxMotorControl: {
            position: 'absolute',
            mt: 1,
            top: '7%',
            right: '1%',
        },
        TaskListWindow: {
            BoxStartStopTaskButton: {
                ml: 2,
                mr: 2,
                mt: 1,
            },
        },
        LeftStackMenu: {
            Stack: {
                position: 'fixed',
                bottom: '2%',
                right: '2%',
            },
        },
    },
    MobileView: {
        BoxOuter: {
            display: 'flex',
            height: '100%',
            flexDirection: 'column',
            justifyContent: 'space-between',
        },
        BoxInner: {
            zIndex: 1,
        },
    },
    TabPanelBox: {
        position: 'absolute',
        left: '0',
        right: '0',
        top: '0',
        bottom: '0',
        overflow: 'auto',
    },
    IconTabsBox: {
        width: '100%',
    },
    DriveTab: {
        OuterBox: {
            display: 'flex',
            height: '100%',
            flexDirection: 'column',
            justifyContent: 'space-between',
        },
        MapBox: {
            flex: 'auto',
            position: 'relative',
            display: 'flex',
            alignItems: 'flex-end',
            flexDirection: 'row-reverse',
        },
        TaskManagerBox: {
            height: '50%',
            flex: 'auto',
            position: 'relative',
            overflow: 'auto',
        },
        Chip: {
            mr: 1,
            mb: 2,
            zIndex: config.mobile_view.chip_z_index,
        },
        Fab: {
            mr: 1,
            mb: 2,
        },
    },
    MachineViewAppBar: {
        FlexGrowBox: {
            flexGrow: 1,
        },
        ContentBox: {
            display: { xs: 'flex' },
            pr: 1,
        },
    },
    MachineControlPanel: {
        Box: {
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'no-wrap',
        },
    },
    TerminalWindow: {
        Box: {
            mt: 2,
            backgroundColor: '#000',
            width: '100%',
            maxHeight: '400px',
            minWidth: '200px',
            minHeight: '400px',
            paddingRight: '3.5em',
            overflow: 'auto',
            position: 'relative',
        },
        Typography: {
            color: '#ffffff',
            fontSize: '9px',
            fontFamily: 'monospace',
            whiteSpace: 'pre-wrap',
        },
    },
    MachineDrawer: {
        Drawer: {
            width: 240,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
                width: 240,
                boxSizing: 'border-box',
                background: '#c7c7c7',
            },
        },
        Box: {
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'no-wrap',
        },
        Typography: {
            mt: 3,
            ml: 2,
        },
    },
    AcceptanceTest: {
        AcceptanceTestContent: {
            List: {
                height: '50%',
                overflowY: 'auto',
                scrollbarWidth: 'thin',
                scrollbarColor: 'transparent transparent',
            },
        },
    },
}
