import React from 'react'
import { Button, CircularProgress } from '@mui/material'
import { ConfirmDialog } from '../_components'
import SaveIcon from '@mui/icons-material/Save'
import { useCropActions, useAlertActions } from '../_actions'
import { H2LFieldJson } from '../types'
import { useNavigate } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { lastRouteLocationState } from '../_state'

interface SaveButtonProps {
    field: H2LFieldJson
    boundary: google.maps.Data
    beds: google.maps.Data
    planted_area: google.maps.Data
}

type DialogData = {
    dialogType: string
    title: string
    message: string
}

export function SaveButton(props: SaveButtonProps) {
    const cropActions = useCropActions()
    const alertActions = useAlertActions()
    const navigate = useNavigate()
    const lastRoute = useRecoilValue(lastRouteLocationState)

    const [submitDialogOpen, setSubmitDialogOpen] = React.useState(false)
    const [submitDialogData, setSubmitDialogData] = React.useState(null)
    const [uploading, setUploading] = React.useState(false)
    const handleOpenSubmitDialog = (dialogData: DialogData): void => {
        setSubmitDialogData(dialogData)
        setSubmitDialogOpen(true)
    }

    const convertToMultiPolygon = (geoJsonData) => {
        return {
            type: 'Feature',
            geometry: {
                coordinates: [geoJsonData.features[0].geometry.coordinates],
                type: 'MultiPolygon',
            },
            properties: geoJsonData.features[0].properties,
            id: geoJsonData.features[0].id,
        }
    }

    const handleUpdateCrop = () => {
        // eslint-disable-next-line
        props.planted_area.toGeoJson((planted_area: any) => {
            // eslint-disable-next-line
            props.boundary.toGeoJson((bound: any) => {
                let b = bound.features[0]
                if (b && b.geometry.type === 'Polygon') {
                    b = convertToMultiPolygon(bound)
                }
                // eslint-disable-next-line
                props.beds.toGeoJson((bs: any) => {
                    const field = {
                        ...props.field,
                        boundary: b,
                        beds: bs.features,
                        planted_area: planted_area.features[0],
                    }
                    setUploading(true)
                    cropActions
                        .updateCropTulipBeds(props.field.crop_id, field)
                        .then((res) => {
                            setUploading(false)
                            alertActions.success('Field updated!')
                            if (lastRoute.startsWith('/m/') && lastRoute.includes('/create-task')) {
                                const machine = lastRoute.split('/')[2] // Extract the 'VARIABLE' part
                                navigate(`/m/${machine}/create-task?crop-id=${res.updated.id}`)
                            } else {
                                navigate(`/fields/${props.field.field_id}/crops/${res.updated.id}/view/`)
                            }
                        })
                        .catch(() => {
                            alertActions.error('Error updating field, we are looking into it!')
                            setUploading(false)
                        })
                })
            })
        })
    }
    const onSave = () => {
        handleUpdateCrop()
        //handleOpenSubmitDialog({
        //dialogType: 'save-changes',
        //title: 'Save changes',
        //message: 'Are you sure that all changes made are correct?',
        //})
    }
    return (
        <>
            <ConfirmDialog
                title={submitDialogData?.title}
                open={submitDialogOpen}
                setOpen={setSubmitDialogOpen}
                onConfirm={handleUpdateCrop}
                type={submitDialogData}
            >
                {submitDialogData && submitDialogData['message']}
            </ConfirmDialog>
            <Button
                variant='contained'
                sx={{
                    position: 'absolute',
                    top: '2%',
                    right: '2%',
                    backgroundColor: 'white',
                    color: 'black',
                }}
                onClick={onSave}
                startIcon={<SaveIcon />}
                endIcon={uploading ? <CircularProgress color='secondary' /> : null}
            >
                SAVE
            </Button>
        </>
    )
}
