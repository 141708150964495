import * as React from 'react'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
    SelectChangeEvent,
} from '@mui/material'
import { User } from '../types'
import { RoleSelect } from './RoleSelect'
import { useTranslation } from 'react-i18next'

interface ChangeRoleDialogProps {
    open: boolean
    user: User | null
    onClose: () => void
    onSave: (role: string) => void
}

const ChangeRoleDialog = ({ open, user, onClose, onSave }: ChangeRoleDialogProps) => {
    const { t } = useTranslation()
    const [selectedRole, setSelectedRole] = React.useState<string>(user?.role || '')
    const handleRoleChange = (event: SelectChangeEvent<string>) => {
        setSelectedRole(event.target.value)
    }

    const handleSave = () => {
        if (user) {
            onSave(selectedRole)
        }
    }

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>
                {t('users:ChangeRoleDialog.changeRoleTitle', { name: user?.name, surname: user?.surname })}
            </DialogTitle>
            <DialogContent>
                <Typography variant='body1'>{t('users:ChangeRoleDialog.selectNewRole')}</Typography>
                <RoleSelect
                    role={selectedRole}
                    allowAdminOption={user?.company_id === 1 || user?.company_id === 16}
                    handleRoleChange={handleRoleChange}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color='primary'>
                    {t('users:ChangeRoleDialog.cancel')}
                </Button>
                <Button onClick={handleSave} color='primary'>
                    {t('users:ChangeRoleDialog.save')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export { ChangeRoleDialog }
