import { Dialog, DialogContent, AppBar, Toolbar, IconButton, Stack, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { TreatmentSafety } from './TreatmentSafety'
import { FluidSafetyIcon } from '../../../_components/icons/FluidSafetyIcon'
import { useRecoilValue } from 'recoil'
import { machineNameAtom } from '../../../_state'
import { DoseLimitAlerts } from './DoseLimitAlerts'
import { useNavigate } from 'react-router-dom'

export const TreatmentSafetyDialog = () => {
    const navigate = useNavigate()
    const machineName = useRecoilValue(machineNameAtom)
    const handleCloseDialog = () => navigate(-1)
    return (
        <>
            <Dialog
                fullScreen
                open={true}
                onClose={handleCloseDialog}
                sx={{
                    '& .MuiPaper-root': {
                        background: '#121212',
                    },
                }}
            >
                <Stack direction={'row'} justifyContent={'space-between'}>
                    <AppBar sx={{ position: 'relative' }}>
                        <Toolbar>
                            <FluidSafetyIcon />
                            <Typography sx={{ flex: 1, ml: 1 }} variant='h6' component='div' noWrap={true}>
                                {`Spuitbeveiliging ${machineName}`}
                            </Typography>
                            <IconButton color='inherit' onClick={handleCloseDialog} aria-label='close' size='large'>
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                </Stack>
                <DialogContent sx={{ pl: 2, pr: 2 }}>
                    <DoseLimitAlerts />
                    <TreatmentSafety />
                </DialogContent>
            </Dialog>
        </>
    )
}
