import * as React from 'react'
import { Slide } from '@mui/material'
import { TransitionProps } from '@mui/material/transitions'
import { PdfView, FullscreenDialog } from '../_components'
export { PdfDialog }

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction='up' ref={ref} {...props} />
})

function PdfDialog({ title, document }: { title: string; document: string }) {
    return (
        <FullscreenDialog
            toolbarTitle={title}
            dialogContent={<PdfView pdf={`${process.env.REACT_APP_API_URL}/api/v1/utils/${document}`} />}
            transitionComponent={Transition}
        />
    )
}
