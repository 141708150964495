import { useState, useEffect } from 'react'
import { Box, Button, Stack, Typography } from '@mui/material'
import { useMachineTransfersActions } from '../../_actions'
import { TranfersOverview } from '../types'
import { useRecoilValue } from 'recoil'
import { machineNameAtom, treatmentRunningSelector } from '../../_state'
import { CollapsibleTable, TransferMachineModal } from './TransferMachineModal'
import { TransferMachineIcon, FullscreenDialog } from '../../_components'
import { useTranslation } from 'react-i18next'

function Transfer() {
    const { t } = useTranslation('machines')
    const machineName = useRecoilValue(machineNameAtom)
    if (!machineName) return

    const [modalOpen, setModalOpen] = useState(false)
    const [machineTransfers, setMachineTransfers] = useState<TranfersOverview | null>(null)
    const treatmentRunning = useRecoilValue(treatmentRunningSelector)

    const machineTransfersActions = useMachineTransfersActions()
    const fetchTransfers = () => machineTransfersActions.getAll(machineName).then(setMachineTransfers)

    useEffect(() => {
        fetchTransfers()
    }, [modalOpen]) // to fetch when the transfer is complete and the modal is closed

    if (!machineTransfers) return null
    return (
        <FullscreenDialog
            toolbarTitle={t('machines:Transfer.transferMachine', { machineName: machineName })}
            toolbarStartIcon={<TransferMachineIcon color='secondary' />}
            dialogContent={
                <Stack spacing={1}>
                    <Typography variant='h5'>
                        {t('machines:Transfer.ownerComp', { ownerCompany: machineTransfers.owner_company })}
                    </Typography>
                    <Typography variant='h5'>
                        {t('machines:Transfer.borrowingComp')}
                        {machineTransfers.borrowing_company &&
                        machineTransfers.borrowing_company !== machineTransfers.owner_company
                            ? machineTransfers.borrowing_company
                            : 'None'}
                    </Typography>
                    <Box sx={{ pt: 2, pb: 2 }}>
                        <Box sx={{ maxHeight: '400px', overflow: 'auto' }}>
                            <CollapsibleTable transfers={machineTransfers} />
                        </Box>
                    </Box>
                    {treatmentRunning && (
                        <Typography variant='body1' color='error'>
                            {t('machines:Transfer.machineTreating')}
                        </Typography>
                    )}
                    <Button
                        variant='contained'
                        color='success'
                        disabled={treatmentRunning}
                        onClick={() => setModalOpen(!modalOpen)}
                    >
                        {t('machines:Transfer.transferMachine', { machineName: machineName })}
                    </Button>
                    {modalOpen && (
                        <TransferMachineModal
                            machineName={machineName}
                            machineTransfers={machineTransfers} // last transfer comp name
                            handleClose={() => setModalOpen(false)}
                        />
                    )}
                </Stack>
            }
        />
    )
}

export { Transfer }
