import { Container, Button, Link, Box, Typography, Paper, Stack } from '@mui/material'
import { Link as RouterLink, Route, Routes, Outlet } from 'react-router-dom'
import { ImageComponent } from '../_components'
import { PdfDialog } from './PdfDialog'
import { useTranslation } from 'react-i18next'

const Message = ({
    by,
    date,
    subject,
    children,
}: {
    by: string
    date: string
    subject: string
    children: JSX.Element
}) => {
    const { t } = useTranslation(['messages'])
    return (
        <Paper elevation={2}>
            <Box sx={{ width: '95%', p: 2 }}>
                <Box>
                    <Typography component='div' sx={{ fontWeight: 'bold' }} display='inline'>
                        Van:
                    </Typography>
                    <Typography component='div' display='inline'>
                        {' '}
                        {by}
                    </Typography>
                </Box>
                <Box>
                    <Typography component='div' sx={{ fontWeight: 'bold' }} display='inline'>
                        Datum:
                    </Typography>
                    <Typography component='div' display='inline'>
                        {' '}
                        {date}
                    </Typography>
                </Box>
                <Box>
                    <Typography component='div' sx={{ fontWeight: 'bold' }} display='inline'>
                        Onderwerp:
                    </Typography>
                    <Typography component='div' display='inline'>
                        {' '}
                        {subject}
                    </Typography>
                    {children}
                </Box>
            </Box>
        </Paper>
    )
}

export const List = () => {
    const BodyMsg1 = () => {
        return (
            <>
                <Typography variant='body2' sx={{ mt: 1, mb: 2, whiteSpace: 'pre-line' }}>
                    {`Beste gebruiker, 
            
          Inmiddels heeft iedereen zijn Selector180 opgehaald en is er door sommigen begonnen met oefenen. Wij verzoeken iedereen die dit nog niet gedaan heeft zo spoedig mogelijk te gaan rijden met de machine om alvast de GPS data die gebruikt wordt te testen. Dit voorkomt eventuele problemen bij het echte selectiewerk.`}
                </Typography>
                <ImageComponent
                    filename={'messages/img1.jpg'}
                    altText={''}
                    style={{ width: '50%', height: '50%', objectFit: 'contain' }}
                />
                <Typography variant='body2' sx={{ mt: 1, whiteSpace: 'pre-line' }}>
                    {`We willen de aandacht nog even vestigen op de megafoon knop, aangegeven in het gele vak hierboven. Als jullie iets geks zien, dit graag registreren via de megafoon knop. Dan wordt het tijdstip geregistreerd en kunnen wij de data terug vinden. Als er een echt probleem is, natuurlijk nog steeds de service lijn bellen. 
          
          Doordat we dit seizoen, vanwege beschikbaarheid, andere hardware moesten gebruiken treedt er dit jaar bij nieuwe machines soms een probleem op met de communicatie naar de camera’s. Wij zijn druk bezig dit probleem te begrijpen en op te lossen. De korte termijn oplossing is een restart van de machine.
        
          We hebben bij meerdere machines de feedback gekregen dat de robot te laag hangt of de achterflap de tulpen raakt. Wij hebben hier inmiddels een software oplossing voor en zullen deze bij alle machines updaten.

          Met vriendelijke groet,

          Uw H2L service en support team.
        `}
                </Typography>
            </>
        )
    }

    const BodyMsg2 = () => {
        return (
            <>
                <Typography variant='body2' sx={{ mt: 1, mb: 2, whiteSpace: 'pre-line' }}>
                    {`Beste gebruiker,

            Het probleem van de communicatie met de camera’s in de 2023 machines is inmiddels begrepen. We zullen de komende week bij iedereen langs komen om dit probleem te verhelpen.

            In de User Interface waar je de velden / bedden kan selecteren voor een taak hebben we de mogelijkheid toegevoegd om handmatig de veld grens, field boundary, te veranderen. In het plaatje hieronder wordt uitgelegd hoe dit werkt.

            Let op: Dit werkt alleen wanneer er al veld grenzen geupload zijn.`}
                </Typography>
                <ImageComponent
                    filename={'messages/img2.png'}
                    altText={''}
                    style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                />
                <Typography variant='body2' sx={{ mt: 1, mb: 2, whiteSpace: 'pre-line' }}>
                    {
                        'Let op: In onderstaand plaatje wordt uitgelegd hoe de machine altijd 1,7 meter binnen de veldgrens blijft.'
                    }
                </Typography>
                <ImageComponent
                    filename={'messages/img3.png'}
                    altText={''}
                    style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                />
                <Typography variant='body2' sx={{ mt: 1, whiteSpace: 'pre-line' }}>
                    {'Het handmatig kunnen ingeven van veldgrenzen wordt op dit moment getest.'}
                </Typography>
                <Typography variant='body2' sx={{ mt: 1, whiteSpace: 'pre-line' }}>
                    {`Deze week komt ook de papieren handleiding beschikbaar.
              
              Met vriendelijke groet,
              
              Uw H2L service en support team.`}
                </Typography>
            </>
        )
    }

    const BodyMsg3 = () => {
        return (
            <>
                <Typography variant='body2' sx={{ mt: 1, mb: 2, whiteSpace: 'pre-line' }}>
                    {`Beste gebruiker, 
            
          Allereerst een mededeling voor de gebruikers met een 2022 machine. Het vloeistofsysteem, de handbediening en de behandelkop zijn hier geüpgraded. Voor een uitleg hoe deze te gebruiken verwijzen we naar de handleiding, `}
                </Typography>
                <Link href={'https://h2lselector.com/manual'}>https://h2lselector.com/manual</Link>
                <Typography variant='body2' sx={{ mt: 1, mb: 2, whiteSpace: 'pre-line' }}>
                    {`paragraaf 2.2, 2.3.1 en 7.2.1. Mocht u er niet uitkomen, bel dan even met de support lijn.
          
          Deze week zijn er twee nieuwe dingen toegevoegd aan de User Interface: 

          1. U kunt vooraf zien waar de machine gaat stoppen, omdat hij te dicht bij de veldgrens komt. 
          
          Er verschijnt een melding als de geplande paden over de boundary heen gaan, of als de machine binnen 1,7 meter van de veldgrens komt. `}
                </Typography>
                <ImageComponent
                    filename={'messages/img4.png'}
                    altText={''}
                    style={{ width: '50%', height: '50%', objectFit: 'contain' }}
                />
                <Typography variant='body2' sx={{ mt: 1, mb: 2, whiteSpace: 'pre-line' }}>
                    {`2. U kon al bestaande veldgrenzen aanpassen, maar nu kunt u ook zelf nieuwe maken, als volgt:
          
          1. Onder het kopje Fields drukt u op EDIT FIELD. Er verschijnt dan een melding boven in het scherm waar staat dat u ergens op de kaart moet klikken om te beginnen met het maken van een boundary.`}
                </Typography>
                <ImageComponent
                    filename={'messages/img5.png'}
                    altText={''}
                    style={{ width: '50%', height: '50%', objectFit: 'contain' }}
                />
                <Typography variant='body2' sx={{ mt: 1, mb: 2, whiteSpace: 'pre-line' }}>
                    {
                        '2. U kunt met twee vingers inzoomen op een hoek van het veld, en dan op de plaats waar u wilt beginnen met de veldgrens tikken. Dit hoeft niet in 1 keer goed te gaan, u kunt de plek later nog verslepen. Vervolgens beweegt u naar de volgende plek rond het veld waar u een hoek in de veldgrens wilt maken. Daar klikt u nogmaals: er verschijnt dan een witte lijn. Let op: De lijn verschijnt pas nadat u het beginpunt en een tweede punt heeft aangetikt.'
                    }
                </Typography>
                <ImageComponent
                    filename={'messages/img6.png'}
                    altText={''}
                    style={{ width: '50%', height: '50%', objectFit: 'contain' }}
                />
                <Typography variant='body2' sx={{ mt: 1, mb: 2, whiteSpace: 'pre-line' }}>
                    {
                        '3. Zo gaat u het hele perceel rond. De grenslijn sluit als u weer klikt op het beginpunt. Vervolgens kan u nog wijzigingen aanbrengen als u niet tevreden was door de witte rondjes te verslepen, of een nieuw punt tussen twee punten maken door middenin aan het grijze bolletje te slepen.'
                    }
                </Typography>
                <ImageComponent
                    filename={'messages/img7.png'}
                    altText={''}
                    style={{ width: '50%', height: '50%', objectFit: 'contain' }}
                />
                <Typography variant='body2' sx={{ mt: 1, whiteSpace: 'pre-line' }}>
                    {`4. Als u klaar bent kunt u rechtsbovenin de veldgrens opslaan (de save knop). De veld grens kan later altijd nog aangepast worden (edit boundary).

            Met vriendelijke groet,
              
            Uw H2L service en support team.`}
                </Typography>
            </>
        )
    }

    const BodyMsg4 = () => {
        return (
            <>
                <Typography variant='body2' sx={{ mt: 1, mb: 2, whiteSpace: 'pre-line' }} component='div'>
                    {`Beste gebruiker, 
          
          Een aantal van jullie heeft aangegeven dat het door ons geadviseerde blauwe pigmentpoeder niet functioneert zoals we voorzien hadden. Het pigment blijkt niet goed te mengen met round-up en bezinkt op de bodem van de vloeistoftank. Dit kan leiden tot verstoppingen in het systeem en een onregelmatig spuitpatroon. Ons advies is om dit pigment `}
                    <Box fontWeight='fontWeightMedium' display='inline'>
                        NIET
                    </Box>
                    {` te gebruiken. We gaan op zoek naar een alternatief en komen er op terug.

            Met vriendelijke groet, 
            
            Uw H2L service en support team.`}
                </Typography>
            </>
        )
    }

    const BodyMsg5 = () => {
        return (
            <>
                <Typography variant='body2' sx={{ mt: 1, mb: 2, whiteSpace: 'pre-line' }} component='div'>
                    {`Beste gebruiker, 
          
          Er is geconstateerd dat op 1 machine de kabels voor de sensoren aan de voorkant niet goed vastgezet zijn met tiewraps en de witte clips zijn losgekomen van het paneel. Hierdoor lagen de kabels in dit geval op de aandrijfketting wat voor grote schade had kunnen zorgen als het niet opgemerkt was.`}
                </Typography>
                <ImageComponent
                    filename={'messages/img8.jpg'}
                    altText={''}
                    style={{ width: '50%', height: '50%', objectFit: 'contain' }}
                />
                <Typography variant='body2' sx={{ mt: 1, mb: 2, whiteSpace: 'pre-line' }} component='div'>
                    {`We vragen jullie allemaal om bij jullie machines een inspectie uit te voeren of de sensor kabels (nog) goed vastzitten en zo niet, deze met wat tiewraps vast te zetten.
            
            Met virendelijke groet,
            
            Uw H2L service en support team.`}
                </Typography>
            </>
        )
    }

    const BodyMsg6 = () => {
        return (
            <>
                <Typography variant='body2' sx={{ mt: 1, mb: 2, whiteSpace: 'pre-line' }}>
                    {`Beste gebruikers, 
          
          Allereerst willen we jullie er op wijzen dat het schoonmaken van de machine met een hogedrukspuit meerdere malen tot problemen met de behandelrobot heeft geleid: deze wordt nat en dit geeft problemen. Dus kijk goed uit en gebruik geen hogedrukspuit!
          
          Deze week hebben we een aantal updates die jullie kunnen gebruiken:
          
          1. Het is nu mogelijk dat jullie zelf de software op de machine upgraden naar de laatste versie. In uw interface scherm ziet u het volgende bericht verschijnen:`}
                </Typography>
                <ImageComponent
                    filename={'messages/img9.png'}
                    altText={''}
                    style={{ width: '75%', height: '75%', objectFit: 'contain' }}
                />
                <Typography variant='body2' sx={{ mt: 1, whiteSpace: 'pre-line' }}>
                    {`Als u wilt updaten, drukt u op de groene knop. Na ongeveer twee minuten is de software geupdated en kunt u weer verder. LET OP: De machine niet uitzetten tijdens de software update, dit kan grote problemen veroorzaken. 
          
          2. Als u de laatste versie van de software hebt geinstalleerd is het ook mogelijk om tijdens een taak de hoogte van de robot aan te passen.
        
          3. Er is een nieuwe manier om te bepalen wanneer de machine begint met het maken van een bocht. Dit is naar aanleiding van opmerkingen over het draaien over tulpenbedden heen. U kunt nu een gebied instellen waar tulpen geplant zijn en vanaf waar de machine mag gaan draaien. De machine zal alleen buiten dit gebied een bocht maken.
        `}
                </Typography>
                <Typography variant='body2' sx={{ pl: 2, mt: 1, whiteSpace: 'pre-line' }}>
                    {`1. Ga naar het kopje ‘FIELDS’. 
            2. Klik op ‘EDIT FIELD’
            3. Klik op ‘EDIT PLANTED-AREA’`}
                </Typography>
                <ImageComponent
                    filename={'messages/img10.png'}
                    altText={''}
                    style={{ width: '75%', height: '75%', objectFit: 'contain' }}
                />
                <Typography variant='body2' sx={{ mt: 1, mb: 2, whiteSpace: 'pre-line' }}>
                    {`4. Nu kunt u, net als bij de boundary, aan de oranje lijn slepen. Selecteer het gebied tot waar de tulpen staan. Onderaan is een schaal toegevoegd om beter te kunnen inschatten tot waar de bedden lopen. 
            5. Klik op ‘SAVE’ om het geplante gebied op te slaan. 
            6. Nu kunt u een taak aanmaken en de oranje lijn als startindicator voor het maken van een bocht gebruiken. 
            7. Selecteer de soorten die u wilt gaan selecteren. 
            8. Selecteer onderaan ‘planted-area’.`}
                </Typography>
                <ImageComponent
                    filename={'messages/img11.png'}
                    altText={''}
                    style={{ width: '75%', height: '75%', objectFit: 'contain' }}
                />
                <Typography variant='body2' sx={{ mt: 1, mb: 2, whiteSpace: 'pre-line' }}>
                    {'9. Klik op ‘CREATE TASK’ om de taak aan te maken.'}
                </Typography>
                <Stack direction={'row'} spacing={1}>
                    <Stack>
                        <Typography variant='body2' sx={{ mt: 1, mb: 2, whiteSpace: 'pre-line' }}>
                            {'Bed-extension:'}
                        </Typography>
                        <ImageComponent
                            filename={'messages/img12.png'}
                            altText={''}
                            style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                        />
                    </Stack>
                    <Stack>
                        <Typography variant='body2' sx={{ mt: 1, mb: 2, whiteSpace: 'pre-line' }}>
                            {'Planted-area:'}
                        </Typography>
                        <ImageComponent
                            filename={'messages/img13.png'}
                            altText={''}
                            style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                        />
                    </Stack>
                </Stack>
                <Typography variant='body2' sx={{ mt: 1, mb: 2, whiteSpace: 'pre-line' }} component='div'>
                    {`Met virendelijke groet,
            
            Uw H2L service en support team.`}
                </Typography>
            </>
        )
    }

    const BodyMsg7 = () => {
        return (
            <>
                <Typography variant='body2' sx={{ mt: 1, mb: 2, whiteSpace: 'pre-line' }}>
                    {`Beste gebruikers,
          
          In de laatste software update(s) zijn de volgende zaken aangepakt:
          
          • Soms slaat de compressor niet altijd goed aan, nadat deze bijvoorbeeld op een kopakker is uitgegaan. Met de update gaat de compressor automatisch aan als de machine aan staat, mits de noodstopknoppen zijn uitgetrokken, en wordt deze niet meer uitgeschakeld.
          • Er zijn meerdere software fouten opgelost die er voor zorgden dat de machine stil kwam te staan zonder dat dit nodig was.
          • Wanneer een van de drie iconen in de UI van groen naar rood gaat, krijgt u nu een sms.
          • De veldgrens / boundary veiligheidsafstand is verlaagd van 1,7 meter naar 1,3 meter vanaf de gps antenne.
          • In de Camera sectie in de UI is vanaf nu een beeld van de robot camera zichtbaar. Dit beeld is maximaal 5 minuten oud.
          
          Verder hebben verschillende machines lekkage aan de dispenser klep bij de spuitkop. De oorzaak hiervan wordt onderzocht in samenwerking met de fabrikant. We zijn op dit moment bijna door onze voorraad reserveonderdelen heen en de fabrikant heeft op dit moment ook geen voorraad. Laat ons weten als u dit probleem ook heeft met de machine, dan kunnen wij dit probleem verder inventariseren. Natuurlijk doen we ons best om dit zo snel mogelijk te verhelpen.
          
          Tot slot hebben we een oplossing om het de kans te verkleinen dat de gezonde plant naast de zieke plant gespoten wordt. We zoeken vrijwilligers om dit te testen. Indien u hieraan mee wilt helpen, graag hiervoor onze service lijn bellen: 015 799 7090.
          
          Met vriendelijke groet,
          
          Uw H2L service en support team.`}
                </Typography>
            </>
        )
    }

    const BodyMsg8 = () => {
        return (
            <>
                <Typography variant='body2' sx={{ mt: 1, mb: 2, whiteSpace: 'pre-line' }}>
                    {`De afgelopen week hebben we het probleem van de lekkende klep(pen) in het dispenser systeem gevonden en kunnen reproduceren: Het blijkt dat het verwarmingselement in sommige gevallen kortsluiting maakt met de dispenser, vervolgens een kleine stroom gaat lopen en dit tot corrosie in de dispenser leidt. Ditkan optreden na duizenden cycli.

            24 gebruikers hebben het nieuwe model voor de robot camera op hun machine getest. Dit vooral om het spuiten van een gezonde plant naast een zieke plant te verbeteren. Dit model geeft duidelijk verbetering en zal op iedere machine geinstalleerd worden met een nieuwe software update.
            
            Verder zouden we 2 dingen willen testen waar we vrijwilligers voor zoeken:
            
            1. We willen graag alternatieve manieren voor het keren op de kopakker testen
            2. We hebben een nieuw herkenningsmodel met daarin de annotaties van vlak voor het seizoen erin verwerkt, deze zouden we willen testen.
            
            Indien u hieraan mee wilt helpen, graag hiervoor onze service lijn bellen: 015 799 7090.
            
            Met vriendelijke groet,
            
            Uw H2L service en support team.`}
                </Typography>
            </>
        )
    }

    const BodyMsg9 = () => {
        return (
            <>
                <Typography variant='body2' sx={{ mt: 1, mb: 2, whiteSpace: 'pre-line' }}>
                    {`Beste gebruikers,
            
            1e en 2e paasdag hebben we beperkte bezetting om support te leveren. De telefoonlijn blijft van 7:00 tot 22:00 bemand, maar support op locatie is beperkt beschikbaar. Wij vragen uw begrip hiervoor.
            
            Wij wensen u en uw familie fijne paasdagen toe !

            Met vriendelijke groet,

            Uw H2L service en support team.`}
                </Typography>
            </>
        )
    }

    const BodyMsg10 = () => {
        return (
            <>
                <Typography variant='body2' sx={{ mt: 1, mb: 2, whiteSpace: 'pre-line' }}>
                    {`Beste gebruikers,
            
            De testen afgelopen week met het nieuwe herkenningmodel hebben verbeterde resultaten opgeleverd, dus we zullen het model nu bij iedereen updaten. Wel is het zo dat in het algemeen de herkenning minder goed werkt als er knoppen in de tulpen zitten, let hier op.
            
            Daarnaast willen we jullie eraan herinneren dat de compressor tank dagelijks afgetapt moet worden. We hebben op een aantal machines pneumatische problemen gezien, vermoedelijk veroorzaakt door vocht in de perslucht, omdat de compressor niet afgetapt was. Dus dagelijks even de compressor aftappen.

            Met vriendelijke groet,

            Uw H2L service en support team.`}
                </Typography>
            </>
        )
    }

    const BodyMsg11 = () => {
        return (
            <>
                <Typography variant='body2' sx={{ mt: 1, mb: 2, whiteSpace: 'pre-line' }}>
                    {`Beste gebruikers,
            
            Met dit bericht informeren we jullie over de uitkomsten van onze enquete en wat we gaan doen om de prestaties en betrouwbaarheid van de machine te verbeteren.
          `}
                </Typography>
                <Typography variant='body2' sx={{ mt: 1, mb: 2, whiteSpace: 'pre-line', fontWeight: 'bold' }}>
                    {'Enquete'}
                </Typography>
                <Typography variant='body2' sx={{ mt: 1, mb: 2, whiteSpace: 'pre-line' }}>
                    {`We hebben in totaal 25 ingevulde enquete formulieren gekregen waaraan door 20 bedrijven is deelgenomen, dit betekent dat 60% van de bedrijven feedback heeft gegeven. Voor 70% van de mensen waren de prestaties van de machine minder dan verwacht, daarentegen was 65% tevreden met de machine. 85% van jullie was tevreden met de support die we geleverd hebben, waarvoor dank.
            
            De belangrijkste zaken waarover mensen ontevreden zijn, hebben te maken met de kwaliteit van het behandelen: “herkent de zieke planten niet”, “behandelde de verkeerde planten”, “behandelde de plant naast de zieke plant”, “behandelde jonge planten”. De prestaties van de machine voor verschillende cultivars staat in de volgende grafiek, waarbij op de verticale as aangegeven is hoe goed de cultivar scoort (100 is het beste).
          `}
                </Typography>
                <ImageComponent
                    filename={'messages/img14.png'}
                    altText={''}
                    style={{ width: '75%', height: '75%', objectFit: 'contain' }}
                />
                <Typography variant='body2' sx={{ mt: 1, mb: 2, whiteSpace: 'pre-line' }}>
                    {`Daarnaast wordt onder andere aangeraden het behandelen / lekken te verbeteren, iets te doen aan het (ongewenst)  stoppen door de ultrasone sensoren,  meer controle krijgen over de rijsnelheid, om de gespoten planten te kunnen zien in de app, schade te voorkomen bij indraaien van het bed en de aandrijving robuuster te maken.
        `}
                </Typography>
                <Typography variant='body2' sx={{ mt: 1, mb: 2, whiteSpace: 'pre-line', fontWeight: 'bold' }}>
                    {`
            Wat gaan we doen ?
          `}
                </Typography>
                <Typography variant='body2' sx={{ mt: 1, mb: 2, whiteSpace: 'pre-line', textDecoration: 'underline' }}>
                    {'Herkenning en behandeling'}
                </Typography>
                <Typography variant='body2' sx={{ mt: 1, mb: 2, whiteSpace: 'pre-line' }}>
                    {`De enquete resultaten laten een zeer gemengd beeld zien: Enkele cultivars scoren redelijk tot goed. Voor enkele cultivars zijn de meningen zeer verdeeld (Strong Gold, Royal Virgin, Strong Love). En andere cultivars scoren slecht. 
            
            Om de prestaties te verbeteren gaan we het volgende doen:
            
            1. Dezelfde beelden door meerdere selecteurs laten annoteren. Hiermee kunnen we selecteurs vergelijken en minder goede annotaties verwijderen voor training van het model.
            2. De beelden uitbreiden met meerdere groeistadia (inclusief jonge planten en planten met knop) en bolmaten om het model beter te trainen op verschillende condities.
            3. Uitzoeken waar annotatie verschillen van dezelfde beelden vandaan komen.
            4. Verbeteren van het robot camera model met nieuwe beelden om te voorkomen dat de gezonde buurman naast een zieke plant bespoten wordt.
            
            Vanaf 30 mei kan er weer geannoteerd worden door jullie, daar zullen we contact over opnemen.
            
            De verbeterde modelen zullen we op machines in Nieuw Zeeland (vanaf half september) gaan verifieren.  Op basis van deze resultaten kunnen we nog een ronde verbeteringen doorvoeren voor het 2024 seizoen in Nederland.
          `}
                </Typography>
                <Typography variant='body2' sx={{ mt: 1, mb: 2, whiteSpace: 'pre-line', textDecoration: 'underline' }}>
                    {'Betrouwbaarheid'}
                </Typography>
                <Typography variant='body2' sx={{ mt: 1, mb: 2, whiteSpace: 'pre-line' }}>
                    {`Gedurende het afgelopen seizoen zijn er zo'n 600 storingsmeldingen binnengekomen via het storingsnummer. Daarvan zijn 500 op afstand opgelost, doorgaans via software. De overige 100 storingen zijn ter plaatse opgelost. Op basis van die inventarisatie en de resultaten van de enquete hebben we de 4 belangrijkste ontwerpwijzigingen geidentificeerd. We zullen deze veranderingen al op de machines in Nieuw Zeeland doen, om ze daarna bij iedereen op de machines te installeren voor het seizoen 2024:
            
            1. Verbeterde spuitkop waarbij de slijtage van de “dispense klep” die we dit jaar gezien hebben verholpen wordt, waardoor minder lekkage zal optreden.
            2. Andere montage van de ultrasone sensoren waardoor de machine minder / niet meer stopt zonder reden.
            3. Een oplossing om te meten hoeveel vloeistof er verbruikt wordt om eventuele lekkage tijdig te detecteren.
            4. Oplossing voor de betrouwbaarheid van de compressor.

            `}
                </Typography>
                <Typography variant='body2' sx={{ mt: 1, mb: 2, whiteSpace: 'pre-line', textDecoration: 'underline' }}>
                    {'Functionaliteit'}
                </Typography>
                <Typography variant='body2' sx={{ mt: 1, mb: 2, whiteSpace: 'pre-line' }}>
                    {`Qua functionaliteit (in de APP) zullen we in ieder geval de volgende items doorvoeren (en deze zullen in Nieuw Zeeland voor het eerst getest worden):
            
            1. Onthouden welke tulp behandeld is en die een tweede keer niet meer behandelen.
            2. Keuze mogelijkheid om de machine snelheid wel / niet aan te passen.
            3. Op een andere manier omdraaien op de kopakker om schade tegen te gaan.
            4. De mogelijkheid om aan te geven waar de machine gaat starten en stoppen.
            
            Met bovenstaande acties verwachten wij zowel de behandelprestaties als de betrouwbaarheid van de machine significant te kunnen verbeteren. Wij zullen jullie de komende dagen benaderen voor het annoteren en daarnaast zullen we na het seizoen in Nieuw Zeeland een update sturen met de resultaten van de verbeteringen.
            
            Tot slot zullen we binnenkort een schema maken voor onderhoud van uw machine(s) en hier een afspraak voor maken.
            
            Met vriendelijke groet,

            Uw H2L team.
          `}
                </Typography>
            </>
        )
    }

    const BodyMsg12 = () => {
        return (
            <>
                <Typography variant='body2' sx={{ mt: 1, mb: 2, whiteSpace: 'pre-line' }}>
                    {`Beste gebruikers,
            
            Op dit moment wordt er hard gewerkt onze herkenningsmodelen te verbeteren om deze vervolgens te gaan testen op machines in Nieuw Zeeland.
            
            Wij nodigen jullie uit voor een bijeenkomst bij Smit Constructie in Winkel volgende week donderdag 20 juli om 16:00. We willen dan de volgende onderwerpen met jullie bespreken:
            
            1. Status van verbeteringen aan de herkennings modelen, waarbij we aan de hand van de echte beelden laten zien hoe we verbeteringen gedaan hebben en wat hiervan de resultaten zijn.
            2. Voor degenen die hebben meegeholpen met annoteren: wat is hiervan de impact ?
            3. Op dit moment focusen we op Strong Gold, Royal Virgin en Yellow Flight, voor welke andere cultivars willen jullie graag verbeteringen zien ?
            4. Bespreken van jullie beschikbaarheid voor annoteren in de komende maanden
            
            We hopen velen van jullie te zien volgende week!
            
            Met vriendelijke groet,
            
            Uw H2L team.
          `}
                </Typography>
            </>
        )
    }

    const BodyMsg13 = () => {
        return (
            <>
                <Typography variant='body2' sx={{ mt: 1, whiteSpace: 'pre-line' }}>
                    {`Beste gebruikers,

            Wij nodigen jullie uit voor een bijeenkomst bij Smit Constructie in Winkel volgende week 
          `}
                </Typography>
                <Typography variant='body2' sx={{ fontWeight: 'bold' }}>
                    {'Dinsdag 7 November om 15:00.'}
                </Typography>
                <Typography variant='body2' sx={{ mb: 2, whiteSpace: 'pre-line' }}>
                    {`We willen dan de volgende onderwerpen met jullie bespreken:

            1. Status verbeteren herkenningsmodelen.
            2. Status hardware verbeteringen voor betrouwbaarheid.
            3. Extra functionaliteit.
            4. Nieuwe dingen voor komend seizoen.

            We hopen velen van jullie te zien volgende week!

            Met vriendelijke groet,

            Uw H2L team.
          `}
                </Typography>
            </>
        )
    }

    const BodyMsg14 = () => {
        return (
            <>
                <Typography variant='body2' sx={{ mt: 1, mb: 2, whiteSpace: 'pre-line' }}>
                    {`Beste gebruikers,
        
        De presentatie van de bijeenkomst deze week is te bekijken hier: `}
                    <Button
                        component={RouterLink}
                        to='teler_update_nov_2023_v01_03.pdf'
                        variant='contained'
                        color='success'
                        size='small'
                        style={{ textDecoration: 'none', color: 'white' }}
                    >
                        Presentatie
                    </Button>
                    {`
        
        Uw H2L team.
        `}
                </Typography>
            </>
        )
    }

    const BodyMsg15 = () => {
        return (
            <>
                <Typography variant='body2' sx={{ mt: 1, mb: 2, whiteSpace: 'pre-line' }}>
                    {`Beste gebruikers,

        Het selectieseizoen staat voor de deur en we wijzen jullie erop dat het verstandig is voor dat het echt begint alvast met jullie machine(s) het land op te gaan om:
            1) de velden met de GPS coordinaten te controleren en.
            2) weer ervaren hoe de machine ook al weer werkt.
        
        Vanaf dit seizoen gaat H2L, op verzoek van de gebruiker, cultivar specifieke herkenningsmodellen trainen en aanbieden. Verdere uitleg hierover vindt u hier: `}
                    <Button
                        component={RouterLink}
                        to='Generieke en specifieke modellen seizoen 2024 v01-01.pdf'
                        variant='contained'
                        color='success'
                        size='small'
                        style={{ textDecoration: 'none', color: 'white' }}
                    >
                        Link
                    </Button>
                    {`
            
            Als u geinteresseerd bent om (samen met anderen) bepaalde modellen te trainen, laat ons dit voor het seizoen begint weten door ons te bellen op de service
            en support lijn, `}
                    {/* <a href='tel:+31157997090'>+31 15 799 7090</a> */}
                    <Link href='tel:+31157997090'>+31 15 799 7090</Link>
                    {`. Wij zullen dan de verzoeken opnemen en bij u terug komen voor het opnemen van de beelden tijdens het seizoen.
            
            Met vriendelijke groet,

            Uw H2L service en support team.
        `}
                </Typography>
            </>
        )
    }

    const BodyMsg16 = () => {
        return (
            <>
                <Typography variant='body2' sx={{ mt: 1, mb: 2, whiteSpace: 'pre-line' }}>
                    {`Beste gebruikers,

                    Inmiddels zijn veel van jullie al volop aan het selecteren met de Selector 180. We merken dat bepaalde vragen over het gebruik van de nieuwe functionaliteiten van de machine vaak voorbij komen bij de service telefoon.

                    We hebben een kort overzicht gemaakt met extra uitleg over de nieuwe functies. Die vindt u in deze link: `}
                    <Button
                        component={RouterLink}
                        to='Selector 180 Nieuwe gebruiksfuncties 2024 v01-01.pdf'
                        variant='contained'
                        color='success'
                        size='small'
                        style={{ textDecoration: 'none', color: 'white' }}
                    >
                        Link
                    </Button>
                    {`
                    
                    Met vriendelijke groet,

                    Uw H2L service en support team.
                    `}
                </Typography>
            </>
        )
    }

    const BodyMsg17 = () => {
        return (
            <Typography variant='body2' sx={{ mt: 1, mb: 2, whiteSpace: 'pre-line' }}>
                {`Beste gebruikers,

                In de afgelopen dagen zijn er gevallen geweest van machines die vastlopen waarbij de motorstroom te hoog opgelopen was waardoor schade kon ontstaan. We willen jullie daarom vragen om van de rijfrequentieregelaars een paar instellingen te wijzigen:

                In de voorste elektrakast aan de rechterzijde van de machine bij de 2 meest linkse frequentieregelaars dienen de instellingen veranderd te worden. Parameter 571 moet op 101 gezet worden en parameter 572 op 10.

                U kunt dit doen door met de pijltjestoets naar "PARA" te gaan en dan met de pijltjestoets naar P 571 te gaan. Druk op "ENT" en verstel de waarde naar 101 en weer "ENT" om te bevestigen. Hetzelfde voor parameter 572. Dan "ESC" om weer terug te gaan naar ready.

                Daarnaast zijn er ook bij enkele machine rupsen losgekomen. Daarom willen we vragen om, indien nodig, de rupsen wat aan te spannen. Dit kan met een sleutel 46.

                Als laatste nog de vraag om de de vloeistoftank niet te overvullen. De maximale vulhoogte is 5 cm onder de vulopening. Bij overvullen kan schade aan de drukregelaar ontstaan.

                Met vriendelijke groet,

                Uw H2L service en support team.
                    `}
            </Typography>
        )
    }

    const BodyMsg18 = () => {
        return (
            <Typography variant='body2' sx={{ mt: 1, mb: 2, whiteSpace: 'pre-line' }}>
                {`Beste gebruikers,

                In de afgelopen dagen zijn er boven gemiddeld problemen geweest met de aandrijving van de machines. De rups komt los, er treden VFD regelaar fouten op en in het ergste geval begeeft de electrische motor het.
                
                Om de problemen hiermee te verlichten hebben we de bocht die de machine op de kopakker maakt iets verruimd, zodat de rupsen minder weerstand ondervinden. Bestaande taken blijven de kleinere bocht gebruiken, maar in de nieuwe taken zal de ruimere bocht meegenomen worden. Mocht dit problemen geven met de veldgrens, laat het ons even weten.
                
                Met vriendelijke groet,
                
                Uw H2L service en support team.
                `}
            </Typography>
        )
    }

    const BodyMsg19 = () => {
        return (
            <Typography variant='body2' sx={{ mt: 1, mb: 2, whiteSpace: 'pre-line' }}>
                {`Beste gebruikers,

                H2L en Smit constructie zijn samen bezig de problemen met aflopende rupsen te onderzoeken. In het ergste geval brandt er een aandrijfmotor door als dit gebeurt. De beveiliging die we daarvoor hebben aangezet in de frequentieregelaar (zie bericht 15 maart) blijkt te gevoelig en zorgt voor extra problemen (VFD fout F401).

                We hebben nu een beveiliging ingebouwd in de eigen machine software. Deze beveiliging detecteert vastlopers in het rupsloopwerk.  De aandrijfmotoren worden stilgezet als deze langer dan 1 minuut niet op toeren komen.
                
                We raden u aan het volgende te doen:
                1.	Installeer de laatste machine software update
                2.	Zet de frequentieregelaar beveiliging terug op de originele waardes:
                Parameter 571  :  0
                Parameter 572  :  0
                (zie het bericht van 15 maart voor instructies hoe dit te doen.)
                
                Met vriendelijke groet,

                Uw H2L service en support team.
                `}
            </Typography>
        )
    }

    const BodyMsg20 = () => {
        return (
            <Typography variant='body2' sx={{ mt: 1, mb: 2, whiteSpace: 'pre-line' }}>
                {`Beste gebruikers,
                
                We hebben de afgelopen tijd gewerkt aan een verbeterde versie van het model voor de robot camera.
                We verwachten hiermee betere behandelprestaties voor tulpen met 1 blad, jonge tulpen en daarmee
                ook een verbetering in het behandelen van buurplanten.

                Vanaf nu als u een nieuwe taak start, zal automatisch dit nieuwe robotcamera model gebruikt worden.
                We horen graag van jullie of het ook echt beter is.

                Op verzoek hebben we ook een verbetering aangebracht met betrekking tot de taken. Vanaf nu
                behandelt de machine alleen de delen van de bedden die zijn beplant met cultivars die zijn geselecteerd
                tijdens het maken van de taak. Als een deel van een bed alleen aan de taak wordt toegevoegd om naar
                het begin of eind van een bed te rijden, worden de tulpen in dit stuk niet behandeld. De behandelrobot
                zal op dit niet te behandelen deel van het bed, in de hoogste stand staan.

                Met vriendelijke groet,
                
                Uw H2L service en support team.
                `}
            </Typography>
        )
    }

    const BodyMsg21 = () => {
        return (
            <Typography variant='body2' sx={{ mt: 1, mb: 2, whiteSpace: 'pre-line' }}>
                {`Beste gebruikers,

                In gevallen waarin de machine niet correct spuit voor een cultivar uit de lijst van 24 cultivars, kunt u ons
                helpen de machine te verbeteren door foto's te verzamelen die zullen worden gebruikt om betere
                modellen te trainen voor volgend jaar. U kunt dat doen door de klantenservice te bellen, het probleem
                uit te leggen en hen gedurende 15 minuten een taak op uw machine te laten starten, waarbij alle
                afbeeldingen worden opgeslagen.

                De problemen waarbij deze methode kan helpen zijn de volgende:

                1. Naburige planten spuiten
                2. Jonge planten spuiten
                3. Planten met knoppen spuiten
                4. Er ontbreken duidelijk zieke planten

                Lijst met 24 ondersteunde cultivars:

                Antarctica, Avantgarde, Bright Sun, Columbus, Denmark, First Star, Flash Point, Hermitage, Jumbo Pink,
                Kobla, Lions Glory, Malaysia, Monte Orange, Orca, Red Princess, Rococo Double, Royal Virgin, Seadov
                Parrot, Strong Gold, Super Parrot, Update, White Flag, White Mountain, Yellow Flight


                Met vriendelijke groet,

                Uw H2L service en support team.
                `}
            </Typography>
        )
    }

    const BodyMsg22 = () => {
        return (
            <Typography variant='body2' sx={{ mt: 1, mb: 2, whiteSpace: 'pre-line' }}>
                {`Beste gebruikers,

                Op verzoek of de machine kan stoppen als het naald icoontje of camera icoontje rood is hebben gewerkt aan deze functie. In de komende week zal dit toegevoegd worden aan de update voor de machine. Deze functie zal direct actief zijn na dat de machine is geüpdatet. 

                Mocht je de machine toch willen laten doorrijden is dat ook mogelijk vanuit de app. Voorafgaand zult u een SMS ontvangen dat de machine gestopt is met rijden. Houdt er wel rekening mee dat dit mogelijk gevolgen kan hebben op de behandel prestaties.

                Als de onderbreking opgeheven wordt zal er nog steeds een waarschuwing zichtbaar zijn zolang er een onderdeel niet volledig functioneert, ook is het dan mogelijk om de taak tijdelijk te onderbreken. 

                Met vriendelijke groet, 

                Uw H2L service en support team. 
                `}
            </Typography>
        )
    }

    const BodyMsg23 = () => {
        return (
            <>
                <Typography variant='body2' sx={{ mt: 1, mb: 2, whiteSpace: 'pre-line' }}>
                    {`Beste gebruikers,

                    Wij hebben de seizoensevaluatie gemaakt, en daarop gebaseerd het plan voor het komende jaar.
                    De samenvatting delen we graag met jullie, via deze link: `}
                    <Button
                        component={RouterLink}
                        to='Telerspresentatie juli 2024 evaluatie en plan v01-04.pdf'
                        variant='contained'
                        color='success'
                        size='small'
                        style={{ textDecoration: 'none', color: 'white' }}
                    >
                        PRESENTATIE
                    </Button>
                    {`
                    
                    Met vriendelijke groet, 

                    Uw H2L service en support team.`}
                </Typography>
            </>
        )
    }

    return (
        <Container sx={{ height: '100%' }}>
            <Typography variant='h3' gutterBottom>
                Messages
            </Typography>
            <Stack spacing={2}>
                <Message
                    by={'H2L service and support team'}
                    date={'11 juli 2024'}
                    subject={'Selector 180 evaluatie 2024 en plan 2025'}
                >
                    <BodyMsg23 />
                </Message>
                <Message
                    by={'H2L service and support team'}
                    date={'16 april 2024'}
                    subject={'Machine stoppen bij rode icoontjes.'}
                >
                    <BodyMsg22 />
                </Message>
                <Message by={'H2L service and support team'} date={'28 maart 2024'} subject={'Herkenning verbeteren'}>
                    <BodyMsg21 />
                </Message>
                <Message
                    by={'H2L service and support team'}
                    date={'27 maart 2024'}
                    subject={'Nieuw model voor robot camera (camera 7)'}
                >
                    <BodyMsg20 />
                </Message>
                <Message by={'H2L service and support team'} date={'21 maart 2024'} subject={'Motor beveiliging'}>
                    <BodyMsg19 />
                </Message>
                <Message by={'H2L service and support team'} date={'20 maart 2024'} subject={'Ruimere bochten'}>
                    <BodyMsg18 />
                </Message>
                <Message
                    by={'H2L service and support team'}
                    date={'15 maart 2024'}
                    subject={'Frequentieregelaars en rupsen'}
                >
                    <BodyMsg17 />
                </Message>
                <Message by={'H2L service and support team'} date={'12 maart 2024'} subject={'Presentatie'}>
                    <BodyMsg16 />
                </Message>
                <Message by={'H2L service and support team'} date={'16 februari 2024'} subject={'Service bericht'}>
                    <BodyMsg15 />
                </Message>
                <Message by={'H2L service and support team'} date={'9 november 2023'} subject={'Presentatie'}>
                    <BodyMsg14 />
                </Message>
                <Message by={'H2L service and support team'} date={'2 november 2023'} subject={'Uitnodiging'}>
                    <BodyMsg13 />
                </Message>
                <Message by={'H2L service and support team'} date={'13 juli 2023'} subject={'Uitnodiging'}>
                    <BodyMsg12 />
                </Message>
                <Message by={'H2L service and support team'} date={'26 mei 2023'} subject={'Service bericht'}>
                    <BodyMsg11 />
                </Message>
                <Message by={'H2L service and support team'} date={'12 april 2023'} subject={'Service bericht'}>
                    <BodyMsg10 />
                </Message>
                <Message by={'H2L service and support team'} date={'7 april 2023'} subject={'Service bericht'}>
                    <BodyMsg9 />
                </Message>
                <Message by={'H2L service and support team'} date={'4 april 2023'} subject={'Service bericht'}>
                    <BodyMsg8 />
                </Message>
                <Message by={'H2L service and support team'} date={'28 maart 2023'} subject={'Service bericht'}>
                    <BodyMsg7 />
                </Message>
                <Message by={'H2L service and support team'} date={'21 maart 2023'} subject={'Service bericht'}>
                    <BodyMsg6 />
                </Message>
                <Message by={'H2L service and support team'} date={'18 maart 2023'} subject={'Service bericht'}>
                    <BodyMsg5 />
                </Message>
                <Message by={'H2L service and support team'} date={'16 maart 2023'} subject={'Service bericht'}>
                    <BodyMsg4 />
                </Message>
                <Message by={'H2L service and support team'} date={'14 maart 2023'} subject={'Service bericht'}>
                    <BodyMsg3 />
                </Message>
                <Message by={'H2L service and support team'} date={'7 maart 2023'} subject={'Service bericht'}>
                    <BodyMsg2 />
                </Message>
                <Message by={'H2L service and support team'} date={'28 februari 2023'} subject={'Service bericht'}>
                    <BodyMsg1 />
                </Message>
            </Stack>
            <Outlet />
        </Container>
    )
}

export const Messages = () => {
    return (
        <Routes>
            <Route path='/*' element={<List />} />
            <Route
                path='teler_update_nov_2023_v01_03.pdf'
                element={<PdfDialog title='Teler Update November 2023' document='teler_update_nov_2023_v01_03.pdf' />}
            />
            <Route
                path='Generieke en specifieke modellen seizoen 2024 v01-01.pdf'
                element={
                    <PdfDialog
                        title='Generieke en specifieke modellen seizoen 2024'
                        document='Generieke en specifieke modellen seizoen 2024 v01-01.pdf'
                    />
                }
            />
            <Route
                path='Selector 180 Nieuwe gebruiksfuncties 2024 v01-01.pdf'
                element={
                    <PdfDialog
                        title='Selector 180 Nieuwe gebruiksfuncties 2024'
                        document='Selector 180 Nieuwe gebruiksfuncties 2024 v01-01.pdf'
                    />
                }
            />
            <Route
                path='Telerspresentatie juli 2024 evaluatie en plan v01-04.pdf'
                element={
                    <PdfDialog
                        title='Telerspresentatie juli 2024 evaluatie en plan v01-04'
                        document='Telerspresentatie juli 2024 evaluatie en plan v01-04.pdf'
                    />
                }
            />
        </Routes>
    )
}
