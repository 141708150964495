import { Stack, Select, SelectChangeEvent, MenuItem, ListItemText } from '@mui/material'
import { RoleIcon } from '../_components'
import { useTranslation } from 'react-i18next'

interface RoleSelectProps {
    role: string
    allowAdminOption: boolean
    handleRoleChange: (e: SelectChangeEvent) => void
}

export const RoleSelect = ({ role, allowAdminOption, handleRoleChange }: RoleSelectProps) => {
    const { t } = useTranslation()

    // Conditionally default the role if admin is not allowed
    const validRole = allowAdminOption || role !== 'admin' ? role : 'manager'

    return (
        <Stack flex={1}>
            <Select value={validRole} onChange={handleRoleChange}>
                {allowAdminOption && (
                    <MenuItem value={'admin'}>
                        <Stack direction={'row'} spacing={1}>
                            <RoleIcon role='admin' />
                            <ListItemText primary={t('users:RoleSelect.admin')} />
                        </Stack>
                    </MenuItem>
                )}
                <MenuItem value={'manager'}>
                    <Stack direction={'row'} spacing={1}>
                        <RoleIcon role='manager' />
                        <ListItemText primary={t('users:RoleSelect.manager')} />
                    </Stack>
                </MenuItem>
                <MenuItem value={'user'}>
                    <Stack direction={'row'} spacing={1}>
                        <RoleIcon role='user' />
                        <ListItemText primary={t('users:RoleSelect.user')} />
                    </Stack>
                </MenuItem>
            </Select>
        </Stack>
    )
}
