import React, { useState, MouseEvent, useEffect } from 'react'
import { useRecoilState } from 'recoil'
import { formatToDutchTimezone } from '../_helpers'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListItemText from '@mui/material/ListItemText'
import Badge from '@mui/material/Badge'
import NotificationsIcon from '@mui/icons-material/Notifications'
import IconButton from '@mui/material/IconButton'
import { Notification } from '../types'
import { useNotificationActions } from '../_actions'
import { notificationsAtom } from '../_state/notifications'
import { useLocation } from 'react-router-dom'

interface NotificationItemsProps {
    notifications: Notification[]
    handleClose: () => void
}

const NotificationItems = ({ notifications, handleClose }: NotificationItemsProps) => {
    return (
        <>
            {notifications.map((notification: Notification) => (
                <MenuItem key={notification.id} onClick={handleClose}>
                    <ListItemText>
                        {notification.type === 'SMS' && '💬 '}
                        {notification.message} {formatToDutchTimezone(notification.created_at, true)}
                    </ListItemText>
                </MenuItem>
            ))}
        </>
    )
}

export const NotificationIndicator = () => {
    const location = useLocation()
    const [notifications, setNotifications] = useRecoilState(notificationsAtom)

    const notificationActions = useNotificationActions()
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
    const open = Boolean(anchorEl)
    const [unreadCount, setUnreadCount] = useState(0)

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        if (!notifications) return
        const unread: Set<number> = new Set(
            notifications
                .filter((obj) => {
                    return obj.is_read === false
                })
                .map((v) => v.id),
        )

        if (notifications.length > 0) {
            setAnchorEl(event.currentTarget)
            handleMarkAsRead(unread)
        }
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleMarkAsRead = (unread: Set<number>) => {
        if (unread.size > 0) notificationActions.markAsRead(unread).then(() => setUnreadCount(0))
        let updatedNotifications: Notification[] | [] = []
        updatedNotifications = notifications.map((notification: Notification) => ({
            ...notification,
            is_read: true,
        }))
        setNotifications(updatedNotifications)
    }

    useEffect(() => {
        if (notifications) {
            setUnreadCount(
                notifications.filter((obj) => {
                    return obj.is_read === false
                }).length,
            )
        }
        return () => {
            setUnreadCount(0)
        }
    }, [notifications])

    const render = () => {
        if (location?.pathname.includes('m/')) {
            return (
                <>
                    <IconButton
                        aria-label='bell'
                        onClick={handleClick}
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup='true'
                        aria-expanded={open ? 'true' : undefined}
                        sx={{ color: 'white' }}
                    >
                        <Badge badgeContent={unreadCount} color='error'>
                            <NotificationsIcon />
                        </Badge>
                    </IconButton>
                    <Menu
                        id='notification-menu'
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        <NotificationItems notifications={notifications} handleClose={handleClose} />
                    </Menu>
                </>
            )
        }
    }
    return render()
}
