import { useState, useEffect, MouseEvent } from 'react'
import UserService from '../services/UserService'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { Link, useSearchParams } from 'react-router-dom'
import { useUserActions, useCompanyActions, useAlertActions } from '../_actions'
import AddIcon from '@mui/icons-material/Add'
import { RoleIcon } from '../_components'
import {
    Container,
    Box,
    Stack,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    Button,
    TableHead,
    TableRow,
    Paper,
    LinearProgress,
    IconButton,
    Popover,
    Autocomplete,
    TextField,
    Badge,
} from '@mui/material'
import { User, Company, HttpResponse } from '../types'
import FilterAltIcon from '@mui/icons-material/FilterAlt'
import SmsIcon from '@mui/icons-material/Sms'
import { UserMenu } from './UserMenu'
import { useTranslation } from 'react-i18next'

export const List = () => {
    const { t } = useTranslation('users')
    const [filterAnchor, setFilterAnchor] = useState<null | HTMLElement>(null)
    const [selectedCompany, setSelectedCompany] = useState<Company | null>()
    const userActions = useUserActions()
    const alertActions = useAlertActions()
    const companyActions = useCompanyActions()
    const queryClient = useQueryClient()
    const [searchParams, setSearchParams] = useSearchParams()

    const usersResult = useQuery<User[]>({
        queryKey: ['users_v3', selectedCompany?.id],
        queryFn: () => userActions.getAll(selectedCompany?.id),
    })

    const companiesResult = useQuery<Company[]>({
        queryKey: ['companies_v4'],
        queryFn: () => companyActions.getAll(),
    })

    const deleteUserMutation = useMutation({
        mutationFn: (userId: number) => userActions.delete(userId),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['users_v3', selectedCompany?.id] })
            alertActions.success(t('users:List.deleteSuccess'))
        },
    })

    const updateUserRoleMutation = useMutation({
        mutationFn: (data: { userId: number; role: string }) => userActions.updateRoles(data.userId, data.role),
        onSuccess: (response: HttpResponse) => {
            alertActions.success(response.detail)
            queryClient.invalidateQueries({ queryKey: ['users_v3', selectedCompany?.id] })
        },
        onError: (error: Error) => {
            alertActions.error(error.message)
        },
    })

    const handleDeleteUser = async (userId: number) => {
        try {
            await deleteUserMutation.mutateAsync(userId)
        } catch (error) {
            console.error('Error deleting user:', error)
        }
    }

    const handleUpdateRole = (userId: number, role: string) => {
        updateUserRoleMutation.mutate({ userId, role })
    }

    const updateUrl = (companyName: string) => setSearchParams({ company: companyName })

    const handleFilterIconClick = (event: MouseEvent<HTMLElement>) => {
        setFilterAnchor(event.currentTarget)
    }

    const handleCloseFilterPopover = () => {
        setFilterAnchor(null)
    }

    useEffect(() => {
        const company = searchParams.get('company')
        if (company && companiesResult.data) {
            const selectedCompanyFromUrl = companiesResult.data.find((c) => c.name === company)
            setSelectedCompany(selectedCompanyFromUrl)
        }
    }, [searchParams, companiesResult.data])

    return (
        <Container sx={{ height: '100%' }}>
            <Stack direction={'row'} justifyContent={'space-between'}>
                <Stack direction={'row'} alignItems={'flex-start'}>
                    <Typography variant='h3' gutterBottom>
                        {t('users:List.users')}
                    </Typography>
                </Stack>
                <Stack direction={'row'} alignSelf={'center'} spacing={1} sx={{ mb: 1 }}>
                    <Link to={'/users/add'}>
                        <Button color='success' variant='contained' startIcon={<AddIcon fontSize='small' />}>
                            {t('users:List.addUser')}
                        </Button>
                    </Link>
                    <Link to={'/users/sms'}>
                        <Button startIcon={<SmsIcon fontSize='small' />} variant='contained' color='success'>
                            {t('users:List.sms')}
                        </Button>
                    </Link>
                </Stack>
            </Stack>
            <Paper elevation={3}>
                <TableContainer component={Paper}>
                    <Table aria-label='simple table'>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Stack direction={'row'} alignItems={'center'}>
                                        {t('users:List.company')}
                                        {UserService.hasRole(['admin']) && !companiesResult.isLoading && (
                                            <>
                                                <IconButton onClick={handleFilterIconClick}>
                                                    <Badge color='success' variant='dot' invisible={!selectedCompany}>
                                                        <FilterAltIcon />
                                                    </Badge>
                                                </IconButton>
                                                <Popover
                                                    open={Boolean(filterAnchor)}
                                                    anchorEl={filterAnchor}
                                                    onClose={handleCloseFilterPopover}
                                                    anchorOrigin={{
                                                        vertical: 'center',
                                                        horizontal: 'center',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'center',
                                                        horizontal: 'center',
                                                    }}
                                                >
                                                    <Box p={1}>
                                                        <Autocomplete
                                                            open={true}
                                                            options={companiesResult.data || []}
                                                            getOptionLabel={(option) => option.name}
                                                            value={selectedCompany ?? null}
                                                            sx={{ width: 300 }}
                                                            renderInput={(params) => <TextField {...params} />}
                                                            onChange={(_, value, reason) => {
                                                                if (reason === 'clear') {
                                                                    updateUrl('')
                                                                    setSelectedCompany(null)
                                                                }
                                                                if (value) {
                                                                    updateUrl(value.name)
                                                                    setSelectedCompany(value)
                                                                }
                                                                handleCloseFilterPopover()
                                                            }}
                                                        />
                                                    </Box>
                                                </Popover>
                                            </>
                                        )}
                                    </Stack>
                                </TableCell>
                                <TableCell>{t('users:List.name')}</TableCell>
                                <TableCell>{t('users:List.email')}</TableCell>
                                <TableCell>{t('users:List.mobile')}</TableCell>
                                <TableCell>{t('users:List.role')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {usersResult.data?.map((user: User) => (
                                <TableRow
                                    hover
                                    key={user.id}
                                    sx={{
                                        cursor: 'pointer',
                                        bgcolor: '#ffffff',
                                        whiteSpace: 'normal',
                                    }}
                                >
                                    <TableCell component='th' scope='row'>
                                        {/* Return the list from back and delete this */}
                                        {(companiesResult.data &&
                                            companiesResult.data.find((x) => x.id === user.company_id)?.name) ||
                                            t('users:List.unknownCompany')}
                                    </TableCell>
                                    <TableCell>
                                        {user.name} {user.surname}
                                    </TableCell>
                                    <TableCell>{user.email}</TableCell>
                                    <TableCell>
                                        <a href={`tel:${user.mobile}`}>{user.mobile}</a>
                                    </TableCell>
                                    <TableCell>
                                        <Stack
                                            direction={'row'}
                                            justifyContent={'space-between'}
                                            alignItems={'self-end'}
                                        >
                                            <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                                <RoleIcon role={user.role} />
                                                {UserService.hasRole(['admin', 'manager']) && (
                                                    <UserMenu
                                                        user={user}
                                                        onDeleteUser={handleDeleteUser}
                                                        onUpdateRole={handleUpdateRole}
                                                    />
                                                )}
                                            </Stack>
                                        </Stack>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {usersResult.isLoading && <LinearProgress color='success' />}
                {usersResult.isError && <>{t('users:List.anErrorHasOccured')}</>}
            </Paper>
        </Container>
    )
}
export default List
