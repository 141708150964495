import React from 'react'
import { GoogleMap, useJsApiLoader, Libraries } from '@react-google-maps/api'
import { useRecoilState } from 'recoil'
import { mapAtom } from '../_state'

interface MapProps {
    children?: React.ReactNode
}

const libraries: Libraries = ['drawing', 'marker']

export function Map({ children }: MapProps) {
    //console.log('[Map]: render', map)
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_API_KEY}`,
        libraries: libraries,
    })
    const [map, setMap] = useRecoilState(mapAtom)

    React.useEffect(() => {
        return () => setMap(null)
    }, [])

    const loadHandler = (map: google.maps.Map) => {
        setMap(map)
        map.setOptions({
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            cameraControl: false, // after the https://www.npmjs.com/package/@types/google.maps is updated remove the @ts-expect-error atm the types does not contain the cameraControl. version=3.58.1
            streetViewControl: false,
            fullscreenControl: false,
            mapTypeControl: false,
            rotateControl: false,
            scaleControl: true,
            mapId: '3b57e677ce70cdef', //this is necessary for rotation, it makes the map use WebGL. I generated it on GoogleCloudConsole
            mapTypeId: 'satellite',
            zoomControl: false,
            zoom: 8,
            center: new window.google.maps.LatLng(51.987, 5.5825),
            minZoom: 6,
            gestureHandling: 'greedy',
            // initialCenter: {
            //   lat: 51.987,
            //   lng: 5.5825,
            // },
        })
    }

    const renderMap = () => {
        return (
            <GoogleMap id='map-canvas' onLoad={loadHandler}>
                {map && children}
            </GoogleMap>
        )
    }

    return isLoaded ? renderMap() : null
}
