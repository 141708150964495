import { useState, MouseEvent, useEffect } from 'react'
import { useRecoilState } from 'recoil'
import Badge from '@mui/material/Badge'
import IconButton from '@mui/material/IconButton'
import { Logbook } from '../types'
import { useLogbookActions } from '../_actions'
import { logbookAtom } from '../_state'
import { useLocation } from 'react-router-dom'
import CampaignIcon from '@mui/icons-material/Campaign'

interface LogbookIndicatorProps {
    handleOpenModal: () => void
}

export const LogbookIndicator = ({ handleOpenModal }: LogbookIndicatorProps) => {
    const location = useLocation()
    const [entries, setEntries] = useRecoilState(logbookAtom)
    const logbookActions = useLogbookActions()
    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)
    const [unreadCount, setUnreadCount] = useState(0)

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        const unread: Set<number> = new Set(
            entries
                .filter((obj) => {
                    return obj.is_read === false
                })
                .map((v) => v.id),
        )

        if (entries.length > 0) {
            setAnchorEl(event.currentTarget)
            handleMarkAsRead(unread)
        }
        handleOpenModal()
    }

    const handleMarkAsRead = (unread: Set<number>) => {
        if (unread.size > 0) logbookActions.markAsRead(unread).then(() => setUnreadCount(0))
        let updatedEntries: Logbook[] | [] = []
        updatedEntries = entries.map((entry: Logbook) => ({
            ...entry,
            // eslint-disable-next-line camelcase
            is_read: true,
        }))
        setEntries(updatedEntries)
    }

    useEffect(() => {
        if (entries) {
            setUnreadCount(
                entries.filter((obj) => {
                    return obj.is_read === false
                }).length,
            )
        }
        return () => {
            setUnreadCount(0)
        }
    }, [entries])

    const render = () => {
        if (location?.pathname.includes('m/')) {
            return (
                <>
                    <IconButton
                        aria-label='bell'
                        onClick={handleClick}
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup='true'
                        aria-expanded={open ? 'true' : undefined}
                        sx={{ color: 'white' }}
                    >
                        <Badge badgeContent={unreadCount} invisible={unreadCount > 0 ? false : true} color='error'>
                            <CampaignIcon fontSize={unreadCount > 0 ? 'medium' : 'large'} />
                        </Badge>
                    </IconButton>
                </>
            )
        }
    }
    return render()
}
