/* eslint-disable camelcase */
import { useEffect, Suspense, lazy, ComponentType } from 'react'
import { io } from 'socket.io-client'
import UserService from '../services/UserService'
import { useParams, Outlet, Routes, Route } from 'react-router-dom'
import { Controller } from './Controller'
import Box from '@mui/material/Box'
import { ThemeProvider } from '@mui/material/styles'
import { mobileDarkTheme } from '../themes'
import CssBaseline from '@mui/material/CssBaseline'
import { styles } from '../styles'
import MachineViewAppBar from '../_components/mobile/MachineViewAppBar'
import { TabManager } from '../_components/mobile/TabManager'
import MachineStatusSub from '../_components/MachineStatusSub'
import { machineNameAtom, machineSocketAtom, h2lFieldJsonAtom, taskQuery, taskManagerOpenAtom } from '../_state'
import { useSetRecoilState, useRecoilValue, useResetRecoilState, useRecoilState } from 'recoil'
import { Transfer } from './transfer/Transfer'
import { TreatmentSafetyDialog } from '../_components/treatment/FluidSafety/TreatmentSafetyDialog'
import OfflineBackdrop from '../_components/OfflineBackdrop'
import CamerasSetup from '../cameras_setup'
import { MachineCreateTask } from './MachineCreateTask'

const Job = lazy(() => import('../jobs'))
const Logbook = lazy(() => import('../logbook'))
const AdjustBedAlignment = lazy(() => import('./adjust_bed_alignment'))
const SystemCommands = lazy(() =>
    import('../_components/SystemCommandsManager').then(
        (module) => ({ default: module.SystemCommands }) as { default: ComponentType<any> },
    ),
)
const CabinetsAssembly = lazy(() => import('../cabinets_assembly'))
const RobotCurrentTest = lazy(() => import('../robot_current_test/'))
const AcceptanceTest = lazy(() => import('../acceptance_test'))

export function RouteListener() {
    const { machine_name } = useParams<Record<string, string>>()
    if (!machine_name) return
    const setMachineName = useSetRecoilState(machineNameAtom)
    useEffect(() => {
        setMachineName(machine_name)
        return () => {
            setMachineName(null)
        }
    }, [machine_name])
    return null
}

export function View() {
    // console.log('[View] render')
    const [machineStatusSocket, setMachineStatusSocket] = useRecoilState(machineSocketAtom)
    const machineName = useRecoilValue(machineNameAtom)
    const setTask = useSetRecoilState(taskQuery)
    const setCrop = useSetRecoilState(h2lFieldJsonAtom)
    const resetTaskManagerOpen = useResetRecoilState(taskManagerOpenAtom)

    useEffect(() => {
        if (machineName) {
            document.title = `${machineName} - H2L Selector`

            setMachineStatusSocket(
                io(`${process.env.REACT_APP_FSS_URL}/${machineName}`, {
                    auth: {
                        token: UserService.getToken(),
                        client_type: 'ui-client',
                    },
                }),
            )
        }

        return () => {
            document.title = 'H2L Selector'
            setMachineStatusSocket(null)
            setTask({ data: null, isLoading: false })
            setCrop(null)
            resetTaskManagerOpen()
        }
    }, [machineName])

    if (!machineStatusSocket) return null

    const Layout = () => (
        <>
            <Controller />
            <Box sx={[styles.MobileView.BoxInner]}>
                <MachineViewAppBar />
            </Box>
            <OfflineBackdrop>
                <TabManager />
            </OfflineBackdrop>
            <Outlet />
        </>
    )
    return (
        <>
            <MachineStatusSub />
            <ThemeProvider theme={mobileDarkTheme}>
                <CssBaseline />
                <Box sx={[styles.MobileView.BoxOuter]}>
                    <Suspense fallback={<div>Loading...</div>}>
                        <Routes>
                            <Route path='create-task' element={<MachineCreateTask />} />
                            <Route path='fluid-safety/*' element={<TreatmentSafetyDialog />} />
                            <Route path='jobs/*' element={<Job />} />
                            <Route path='logbook/*' element={<Logbook />} />
                            <Route path='system-commands/*' element={<SystemCommands />} />
                            <Route path='acceptance-tests/*' element={<AcceptanceTest />} />
                            <Route path='adjust-bed-alignment/*' element={<AdjustBedAlignment />} />
                            <Route path='cameras-setup/*' element={<CamerasSetup />} />
                            <Route path='cabinets-test-bench/*' element={<CabinetsAssembly />} />
                            <Route path='robot-current-test/*' element={<RobotCurrentTest />} />
                            <Route path='transfer/*' element={<Transfer />} />
                            <Route path='/*' element={<Layout />} />
                        </Routes>
                    </Suspense>
                </Box>
            </ThemeProvider>
        </>
    )
}
