import { ElementType } from 'react'
import { Icon } from '.'
import { useTheme } from '@mui/material/styles'
import { useRecoilValue } from 'recoil'
import { SteeringWheelIcon } from './icons/SteeringWheelIcon'
import { SyringeIcon } from './icons/SyringeIcon'
import { VideoIcon } from './icons/VideoIcon'
import { driveState, treatmentState, camerasState } from '../_state/indicatorState'

type IndicatorType = 'drive' | 'treatment' | 'cameras'

export const IndicatorIcon = ({ type }: { type: string }) => {
    const theme = useTheme()

    const selectors = {
        drive: driveState,
        treatment: treatmentState,
        cameras: camerasState,
    }

    // Ensure that `type` is always a key in `selectors`.
    const selector: string = useRecoilValue(selectors[type])

    const IconComponents: Record<IndicatorType, ElementType> = {
        drive: SteeringWheelIcon,
        treatment: SyringeIcon,
        cameras: VideoIcon,
    }

    const IconComp = IconComponents[type]

    const color =
        {
            error: theme.palette.error.main, // #f44336
            loading: theme.palette.secondary.main, // #fff
            idle: theme.palette.secondary.main, // #fff
            running: theme.palette.success.main, // #66bb6a
        }[selector] || theme.palette.grey[500]

    return <Icon color={color} IconComponent={IconComp} loading={selector === 'loading'} />
}
