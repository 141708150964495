import { useEffect, useRef } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { mapAtom, taskSettingsState } from '../_state'
import { Headland, MultiPolygon } from '../types'
import { addStyleFromGeoJSON } from '../_helpers'
import { createPUPMarker } from '../_components/icons/LocationPin'

// Load the Advanced Marker Element library
// const { AdvancedMarkerElement } = (await google.maps.importLibrary('marker')) as google.maps.MarkerLibrary
//
type Polygon = google.maps.Polygon
type LatLngLiteral = google.maps.LatLngLiteral
function geoJsonToGooglePolygons(multiPolygon: MultiPolygon): Polygon[] {
    if (multiPolygon.type !== 'MultiPolygon') {
        throw new Error('Input must be a MultiPolygon')
    }

    return multiPolygon.coordinates.map((polygonCoords) => {
        // First array is outer boundary, rest are holes
        const paths: LatLngLiteral[][] = polygonCoords.map((ring) =>
            ring.map(([lng, lat]) => ({
                lat,
                lng,
            })),
        )

        return new google.maps.Polygon({
            paths,
        })
    })
}

interface PickUpPointPinProps {
    isVisible: boolean
    headland: Headland
}

export function PickUpPointPin(props: PickUpPointPinProps) {
    const map = useRecoilValue(mapAtom)
    const setTaskSettings = useSetRecoilState(taskSettingsState)
    const marker = useRef<google.maps.marker.AdvancedMarkerElement | null>(null)
    const previousPosition = useRef<google.maps.LatLng | null>(null)
    const headlandData = useRef<google.maps.Data>(new google.maps.Data())

    const polygons = geoJsonToGooglePolygons(props.headland.geometry)

    useEffect(() => {
        headlandData.current.addGeoJson(props.headland)
        headlandData.current.setMap(map)
        addStyleFromGeoJSON(headlandData.current)

        headlandData.current.forEach((feature) => {
            headlandData.current.overrideStyle(feature, {
                visible: props.isVisible,
                fillOpacity: 0.35,
                clickable: false,
            })
        })

        return () => {
            headlandData.current.setMap(null)
        }
    }, [props.headland])

    useEffect(() => {
        if (headlandData.current) {
            headlandData.current.forEach((feature) => {
                headlandData.current.overrideStyle(feature, { visible: props.isVisible })
            })
        }
    }, [props.isVisible])

    useEffect(() => {
        if (!props.isVisible || marker.current) return
        const position = polygons[0].getPath().getArray()[0].toJSON()
        const [pickUpPointMarker, pinElement] = createPUPMarker(map, position, true)

        pickUpPointMarker.addListener('dragstart', () => {
            pinElement.style.cursor = 'grabbing'
            previousPosition.current = pickUpPointMarker.position as google.maps.LatLng
            headlandData.current.forEach((feature) => {
                headlandData.current.overrideStyle(feature, {
                    fillOpacity: 0.75,
                })
            })
        })

        pickUpPointMarker.addListener('dragend', () => {
            pinElement.style.cursor = 'grab'
            const newPosition = pickUpPointMarker.position as google.maps.LatLng
            headlandData.current.forEach((feature) => {
                headlandData.current.overrideStyle(feature, {
                    visible: props.isVisible,
                    fillOpacity: 0.35,
                })
            })
            let pupInHeadland = false
            polygons.forEach((poly) => {
                if (google.maps.geometry.poly.containsLocation(newPosition, poly)) {
                    pupInHeadland = true
                }
            })
            if (pupInHeadland) {
                previousPosition.current = newPosition
                const pos = newPosition.toJSON()
                setTaskSettings((prev) => ({
                    ...prev,
                    pick_up_point: { type: 'Point', coordinates: [pos.lng, pos.lat] },
                }))
            } else {
                headlandData.current.forEach((feature) => {
                    headlandData.current.overrideStyle(feature, { visible: false })
                    setTimeout(() => {
                        headlandData.current.overrideStyle(feature, { visible: true })
                    }, 150)
                    setTimeout(() => {
                        headlandData.current.overrideStyle(feature, { visible: false })
                    }, 300)
                    setTimeout(() => {
                        headlandData.current.overrideStyle(feature, { visible: true })
                    }, 450)
                })
                pickUpPointMarker.position = previousPosition.current
            }
        })
        marker.current = pickUpPointMarker

        return () => {
            if (marker.current) {
                marker.current.map = null
            }
        }
    }, [props.isVisible])

    // Update marker visibility
    useEffect(() => {
        if (marker.current && props.isVisible) {
            const newPosition = marker.current.position as google.maps.LatLng
            const pos = newPosition.toJSON()
            setTaskSettings((prev) => ({
                ...prev,
                pick_up_point: { type: 'Point', coordinates: [pos.lng, pos.lat] },
            }))
        }
        if (marker.current) {
            marker.current.map = props.isVisible ? map : null
        }
    }, [props.isVisible])

    return null
}
