import { useFetchWrapper } from '../_helpers'
import { User, UserFormData } from '../types'

function useUserActions() {
    const baseUrl = `${process.env.REACT_APP_API_URL}/api/v1`
    const fetchWrapper = useFetchWrapper()

    return {
        register,
        getAll,
        getById,
        updateRoles,
        changeLocale,
        delete: _delete,
    }

    function register(user: UserFormData) {
        return fetchWrapper.post(`${baseUrl}/users`, user)
    }

    function getAll(companyID?: number) {
        if (companyID) return fetchWrapper.get(`${baseUrl}/users?company_id=${companyID}`)
        return fetchWrapper.get(`${baseUrl}/users`)
    }

    function getById(id: number) {
        return fetchWrapper.get(`${baseUrl}/users/${id}`)
    }

    function updateRoles(id: number, role: string) {
        return fetchWrapper.put(`${baseUrl}/users/${id}/roles`, { role })
    }

    function changeLocale(locale: string) {
        return fetchWrapper.put(`${baseUrl}/users/me/locale`, { locale })
    }

    // prefixed with underscored because delete is a reserved word in javascript
    function _delete(id: number): Promise<User> {
        return fetchWrapper.delete(`${baseUrl}/users/${id}`)
    }
}

export { useUserActions }