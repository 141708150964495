import React from 'react'
import { useRecoilValue } from 'recoil'
import { machineTaskStatus, machineControlAtom } from '../_state'
import { InferenceModelSelector, ModalBox, StartStopTaskButton } from './'
import { IconButton } from '@mui/material'
import { GetBtnIcon } from './GetBtnIcon'
import { useTheme } from '@mui/material/styles'
import { useGetBtnColor } from '../_helpers'
import { useTranslation } from 'react-i18next'

export const StartJobWrapper = () => {
    const { t } = useTranslation('tasks')
    const theme = useTheme()
    const [modalOpen, setModalOpen] = React.useState(false)
    const machineTaskState = useRecoilValue(machineTaskStatus)
    const machineControl = useRecoilValue(machineControlAtom)

    const RenderBtn = () => {
        if (machineTaskState === 'start' || machineTaskState === 'resume') {
            return (
                <IconButton
                    key='start-stop-task'
                    sx={{
                        '& .MuiButton-startIcon': { marginRight: '0px', marginLeft: '0px' },
                        bgcolor: useGetBtnColor(machineTaskState),
                        ':hover': {
                            backgroundColor: useGetBtnColor(machineTaskState),
                        },
                    }}
                    disabled={!machineControl}
                    onClick={() => setModalOpen(true)}
                    size='large'
                >
                    {GetBtnIcon(machineTaskState)}
                </IconButton>
            )
        } else {
            return <StartStopTaskButton />
        }
    }

    return (
        <>
            {modalOpen && (
                <ModalBox
                    title={t('tasks:StartJobWrapper.title')}
                    open={modalOpen}
                    handleDialogClose={() => setModalOpen(false)}
                >
                    <InferenceModelSelector handleModalClose={() => setModalOpen(false)} />
                </ModalBox>
            )}
            {<RenderBtn />}
        </>
    )
}
