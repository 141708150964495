import { useRecoilValue } from 'recoil'
import LoadingButton from '@mui/lab/LoadingButton'
import RouteIcon from '@mui/icons-material/Route'
import { groupIDsToBedIDsSelector } from '../_state'
import { HandleCreateTask } from '../types'
import { useTranslation } from 'react-i18next'

export { CreateTaskButton }

function CreateTaskButton({
    btnLoading,
    handleCreateTask,
}: {
    btnLoading: boolean
    handleCreateTask: HandleCreateTask
}) {
    const { t } = useTranslation('tasks')
    const selectedBedIDs = useRecoilValue(groupIDsToBedIDsSelector)

    return (
        <LoadingButton
            disabled={selectedBedIDs.length === 0}
            loading={btnLoading}
            loadingPosition='start'
            startIcon={<RouteIcon />}
            key='create-task'
            sx={{
                p: 1,
                backgroundColor: selectedBedIDs.length > 0 ? 'white' : 'rgba(0, 0, 0, 0.12)', // White when enabled
                color: selectedBedIDs.length > 0 ? 'black' : 'rgba(0, 0, 0, 0.26)', // Black text when enabled
                '&.Mui-disabled': {
                    backgroundColor: '#2f2f2f', // Override MUI default transparency
                    color: '#6d6d6d', // Adjust color for disabled state
                },
            }}
            variant='contained'
            size='large'
            onClick={() => {
                handleCreateTask(selectedBedIDs)
            }}
            fullWidth
        >
            {t('tasks:CreateTaskButton.createTask')}
        </LoadingButton>
    )
}
