import { H2LFieldJson, PlantedArea } from '../../src/types'

/**
 * Update a map's viewport to fit each geometry in a dataset
 * https://developers.google.com/maps/documentation/javascript/examples/layer-data-dragndrop
 */
export function zoomMap(map: google.maps.Map): void {
    const bounds = new window.google.maps.LatLngBounds()
    map.data.forEach((feature) => {
        const geometry = feature.getGeometry()

        if (geometry) {
            processPoints(geometry, bounds.extend, bounds)
        }
    })
    map.fitBounds(bounds)
}
export function zoomMapLayer(map: google.maps.Map, layer: google.maps.Data): void {
    const bounds = new window.google.maps.LatLngBounds()
    layer.forEach((feature) => {
        const geometry = feature.getGeometry()

        if (geometry) {
            processPoints(geometry, bounds.extend, bounds)
        }
    })
    map.fitBounds(bounds)
}

/**
 * Process each point in a Geometry, regardless of how deep the points may lie.
 * https://developers.google.com/maps/documentation/javascript/examples/layer-data-dragndrop
 */
export function processPoints(
    geometry: google.maps.Data.Geometry,
    callback: (arg: google.maps.LatLng | google.maps.LatLngLiteral) => google.maps.LatLngBounds,
    thisArg: google.maps.LatLngBounds,
): void {
    if (geometry instanceof window.google.maps.LatLng) {
        callback.call(thisArg, geometry)
    } else if (geometry instanceof window.google.maps.Data.Point) {
        callback.call(thisArg, geometry.get())
    } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        geometry.getArray().forEach((g) => {
            processPoints(g, callback, thisArg)
        })
    }
}

export function addStyleFromGeoJSON(data: google.maps.Data) {
    /**
     * setStyle runs for all the features the only way to modify
     * them seperatly is to change the json responses from the API
     */
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    data.setStyle((feature) => {
        return {
            strokeColor: feature.getProperty('skip') ? '#000000' : feature.getProperty('strokeColor'),
            strokeWeight: feature.getProperty('strokeWeight'),
            strokeOpacity: feature.getProperty('strokeOpacity'),
            fillColor: feature.getProperty('fillColor'),
            fillOpacity: feature.getProperty('fillOpacity'),
            zIndex: feature.getProperty('row_number') ? 3 : 1, //beds on top of boundary
        }
    })
}

export function addFeaturesToMap(data: google.maps.Data, field: H2LFieldJson, drawPlantedArea = false): void {
    if (field.boundary) {
        data.addGeoJson(field.boundary)
    }

    if (drawPlantedArea) {
        data.addGeoJson(field.planted_area)
    }

    data.addGeoJson({
        type: 'FeatureCollection',
        features: field.beds,
    })

    addStyleFromGeoJSON(data)
}

export function removeFeaturesFromMap(data: google.maps.Data): void {
    data.forEach(function (feature) {
        data.remove(feature)
    })
}
