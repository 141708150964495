import React, { useState } from 'react'
import { Slider, Typography, Stack } from '@mui/material'
import { valuetext } from '../../../_helpers/utils'
import {
    fluidInstrumentationDispenseFlowSelector,
    fluidInstrumentationDetectionDensitySelector,
    fluidInstrumentationTankLevelSelector,
    fluidInstrumentationDispenseVolumeSelector,
} from '../../../_state'
import { useRecoilValue } from 'recoil'
import { ThreeTulipsIcon } from '../../../_components/icons/ThreeTulipsIcon'
import { useTheme } from '@mui/material/styles'

interface TreatmentSafetyRangeSliderProps {
    value: number[]
    disabled: boolean
    step: number
    min: number
    max: number
    metricUnit: string
    socketAttr?: string
    leftIcon?: any
    rightIcon?: any
    handleUpdateSettings: any
}

export const TreatmentSafetyRangeSlider = ({
    value,
    disabled,
    step,
    min,
    max,
    metricUnit,
    socketAttr,
    leftIcon,
    rightIcon,
    handleUpdateSettings,
}: TreatmentSafetyRangeSliderProps) => {
    const theme = useTheme()
    const [sliderValue, setSliderValue] = useState(value)

    const selector =
        socketAttr === 'dispense_volume'
            ? fluidInstrumentationDispenseVolumeSelector
            : socketAttr === 'dispense_flow'
              ? fluidInstrumentationDispenseFlowSelector
              : socketAttr === 'detection_density'
                ? fluidInstrumentationDetectionDensitySelector
                : socketAttr === 'tank_level'
                  ? fluidInstrumentationTankLevelSelector
                  : undefined

    const markValue = selector ? useRecoilValue(selector) : null

    const handleChangeCommited = (e: Event | React.SyntheticEvent<Element, Event>, value: number | number[]) => {
        handleUpdateSettings(value)
    }
    const handleChange = (e: Event | React.SyntheticEvent<Element, Event>, newValue: number | number[]) => {
        setSliderValue(newValue as number[])
    }

    React.useEffect(() => {
        if (value > max[0]) {
            setSliderValue([max])
        } else if (value < min[0]) {
            setSliderValue([min])
        } else {
            setSliderValue(value)
        }
    }, [value])

    return (
        <>
            <Stack sx={{ width: '100%' }}>
                <Typography variant='caption' sx={{ textAlign: 'center', mb: '-10px' }}>
                    {metricUnit}
                </Typography>
                <Stack direction={'row'} alignItems={'center'}>
                    {leftIcon}
                    <Typography variant='caption' sx={{ ml: 0.5 }}>
                        {min}
                    </Typography>
                    <Slider
                        sx={{
                            pointerEvents: 'none !important',
                            '& .MuiSlider-rail, & .MuiSlider-track': {
                                pointerEvents: 'none',
                            },
                            '& .MuiSlider-thumb': {
                                pointerEvents: 'auto',
                            },
                            '& .MuiSlider-mark': {
                                height: '35px',
                                width: '5px',
                                backgroundColor: theme.palette.success.main,
                                opacity: 1,
                            },
                            mr: 2,
                            ml: 2,
                        }}
                        valueLabelDisplay={'auto'}
                        color='secondary'
                        step={step}
                        min={min}
                        max={max}
                        disabled={disabled}
                        getAriaValueText={valuetext}
                        // if markValue > max set to max do the same for min
                        marks={markValue !== null ? [{ value: Math.min(Math.max(markValue, min), max) }] : []}
                        value={sliderValue}
                        onChangeCommitted={handleChangeCommited}
                        onChange={handleChange}
                        disableSwap
                    />
                    <Typography variant='caption' sx={{ mr: 0.5 }}>
                        {max}
                    </Typography>
                    {rightIcon}
                </Stack>
            </Stack>
        </>
    )
}
