import { useRef, useEffect } from 'react'
import { useRecoilValue } from 'recoil'
import { mapAtom, subframePositionAtom } from '../_state'

export { RobotIcon }

function RobotIcon(): null {
    // console.log('[RobotIcon]: render')
    const map = useRecoilValue(mapAtom)
    const subframePosition = useRecoilValue(subframePositionAtom)
    const initialRender = useRef(true)
    const robotIcon = useRef<google.maps.Marker>(
        new window.google.maps.Marker({
            map,
            icon: {
                path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                fillColor: '#00FF00',
                strokeColor: '#00FF00',
                fillOpacity: 1,
                scale: 3,
            },
        }),
    )
    useEffect(() => {
        return () => {
            robotIcon.current.setMap(null)
        }
    }, [])

    if (subframePosition && subframePosition.lat && subframePosition.lon) {
        const { lat, lon, heading, qual } = subframePosition

        if (initialRender.current) {
            const bounds = new window.google.maps.LatLngBounds()
            bounds.extend(new window.google.maps.LatLng(lat, lon))
            map.fitBounds(bounds)
            initialRender.current = false
        }

        robotIcon.current.setPosition(new window.google.maps.LatLng(lat, lon))

        let indicator: google.maps.SymbolPath | null = null
        let rotation: number | undefined = heading

        if (heading === undefined) {
            indicator = window.google.maps.SymbolPath.CIRCLE
            rotation = undefined
        } else {
            indicator = window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW
            const mapHeading = map.getHeading() ?? 0 // Default to 0 if map.getHeading() is undefined
            rotation = heading - mapHeading
        }

        robotIcon.current.setIcon({
            path: indicator,
            strokeColor: qual !== 4 ? '#FF0000' : '#00FF00',
            fillColor: qual !== 4 ? '#FF0000' : '#00FF00',
            fillOpacity: 1,
            scale: 3,
            rotation,
        })
        robotIcon.current.setMap(map)
    } else {
        robotIcon.current.setMap(null)
    }

    return null
}
