import { atom, selector } from 'recoil'
import { FollowPathStatus } from '../types'
import { treatmentAtom } from './treatment'

const followPathAtom = atom<FollowPathStatus | null>({
    key: 'follow_path',
    default: null,
})

const estimatedTimeLeftAtom = atom<number | null>({
    key: 'estimatedTimeLeft',
    default: null,
})

const followPathRunningSelector = selector<boolean | null>({
    key: 'follow_path_running',
    get: ({ get }) => get(followPathAtom)?.status === 3 || null,
})

const states = {
    0: 'INIT',
    1: 'IDLE',
    2: 'OUTRANGE',
    3: 'INRANGE',
    4: 'FORWARD',
    5: 'FEXT',
    6: 'BACKWARD',
    7: 'BEXT',
    8: 'ROTATE',
    9: 'APPROACH',
}

const followPathStateSelector = selector<string | null>({
    key: 'follow_path_status_selector',
    get: ({ get }) => {
        const followPathState = get(followPathAtom)?.state
        return followPathState ? states[followPathState] : null
    },
})

const calculateTimeEstimate = (timeLeftInSeconds: number, speedControl: number): number => {
    if (speedControl == 1) {
        // Low
        return 2 * timeLeftInSeconds
    } else if (speedControl == 2) {
        // Auto
        return 1.333 * timeLeftInSeconds
    } else {
        // High and old machine software
        return timeLeftInSeconds
    }
}
const followPathCountDownSelector = selector<number | null>({
    key: 'follow_path_countdown',
    get: ({ get }) => {
        const timeLeftInSeconds = get(estimatedTimeLeftAtom)
        const speedControl = get(treatmentAtom)?.speed_control
        if (!timeLeftInSeconds || !speedControl) return null

        return calculateTimeEstimate(timeLeftInSeconds, speedControl)
    },
})

export {
    followPathAtom,
    followPathRunningSelector,
    followPathCountDownSelector,
    estimatedTimeLeftAtom,
    followPathStateSelector,
}
