import { ErrorBoundary } from 'react-error-boundary'
import { useFetchWrapper } from '../_helpers'
import { useNavigate } from 'react-router'
import UserService from '../services/UserService'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred'

export { ErrorWrapper }

interface ErrorWrapperProps {
    children: React.ReactNode
}

function ErrorFallback() {
    const navigate = useNavigate()
    return (
        <div>
            <Box
                sx={{
                    mt: 10,
                }}
                textAlign='center'
            >
                <ReportGmailerrorredIcon sx={{ m: 2, fontSize: 80 }} />
                <h1>Something went wrong, we are working on it</h1>
                <Button
                    sx={{
                        mt: 4,
                    }}
                    variant='outlined'
                    color='success'
                    onClick={() => {
                        navigate('/')
                        window.location.reload()
                    }}
                >
                    Try again
                </Button>
            </Box>
            ;
        </div>
    )
}

function ErrorWrapper({ children }: ErrorWrapperProps) {
    const baseUrl = `${process.env.REACT_APP_API_URL}/api/v1`
    const fetchWrapper = useFetchWrapper()
    const navigate = useNavigate()

    const influxErrLogger = (error: Error, errorInfo: React.ErrorInfo) => {
        return fetchWrapper.post(`${baseUrl}/utils/ui_error`, {
            user_email: UserService.getUsername(),
            url: window.location.href,
            message: error.message,
            stack: error.stack,
            component_stack: errorInfo.componentStack,
            user_agent: navigator.userAgent,
            version: 'v2024.4.24',
        })
    }

    return (
        <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onError={influxErrLogger}
            onReset={() => {
                navigate('/machines')
            }}
        >
            {children}
        </ErrorBoundary>
    )
}
