import { useState, MouseEvent } from 'react'
import { RenameDialog, ConfirmDialog } from '../_components'
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import AbcIcon from '@mui/icons-material/Abc'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { useAlertActions, useTaskRequestActions } from '../_actions'
import { TaskListItem } from '../types'
import { useRecoilState } from 'recoil'
import { taskRequestsAtom } from '../_state'
import { useTranslation } from 'react-i18next'

export const TaskItemMenu = ({ taskListItem }: { taskListItem: TaskListItem }) => {
    const { t } = useTranslation(['tasks'])
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const [taskListItems, setTaskListItems] = useRecoilState(taskRequestsAtom)
    const [renameDialogOpen, setRenameDialogOpen] = useState<any>(null)
    const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false)
    const trActions = useTaskRequestActions()
    const alert = useAlertActions()

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        event.stopPropagation()
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => setAnchorEl(null)

    const handleRename = async (taskRequestID: number, newName: string) => {
        await trActions
            .rename(taskRequestID, newName)
            .then((res) => {
                setTaskListItems((prevTaskRequests) => {
                    return prevTaskRequests.map((tr) => (tr.id === res['id'] ? res : tr))
                })
            })
            .catch(() => alert.error(t('tasks:Tasks.couldNotRename')))
            .finally(() => handleClose())
    }

    const handleDeleteTaskRequest = async (taskRequestID: number) => {
        await trActions
            .softDelete(taskRequestID)
            .then((res) => {
                setTaskListItems(taskListItems.filter((tr: TaskListItem) => tr.id !== res['id']))
            })
            .catch(() => alert.error(t('tasks:Tasks.couldNotDelete')))
            .finally(() => alert.success(t('tasks:Tasks.deleted')))
    }

    const options = [
        <MenuItem
            key={`rename-${taskListItem.id}`}
            onClick={(event) => {
                event.stopPropagation()
                setRenameDialogOpen(taskListItem.id)
            }}
        >
            <ListItemIcon>
                <AbcIcon />
            </ListItemIcon>
            <ListItemText>{t('tasks:Tasks.rename')}</ListItemText>
        </MenuItem>,
        <MenuItem
            key={`delete-${taskListItem.id}`}
            onClick={(event) => {
                event.stopPropagation()
                setDeleteDialogOpen(true)
            }}
        >
            <ListItemIcon>
                <DeleteOutlineIcon fontSize='small' />
            </ListItemIcon>
            <ListItemText>{t('tasks:Tasks.delete')}</ListItemText>
        </MenuItem>,
    ]

    return (
        <>
            <IconButton
                size='small'
                aria-label='more'
                id='long-button'
                aria-controls={anchorEl ? 'long-menu' : undefined}
                aria-expanded={anchorEl ? 'true' : undefined}
                aria-haspopup='true'
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                id='long-menu'
                MenuListProps={{
                    'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                onClose={handleClose}
            >
                {options.map((option) => option)}
            </Menu>
            {renameDialogOpen && (
                <RenameDialog
                    entryID={renameDialogOpen}
                    entry={taskListItem.name}
                    validationRegex={/^[a-zA-Z\s\d-_]*$/} // Allow only upper and lower case letters, spaces, and dashes
                    dialogText={t('tasks:Tasks.typeNewTaskName')}
                    helperText={t('tasks:Tasks.valuesAllowed')}
                    handleClose={() => setRenameDialogOpen(false)}
                    handleRename={handleRename}
                />
            )}
            {deleteDialogOpen && (
                <ConfirmDialog
                    title={t('tasks:Tasks.deleteForField', {
                        field_name: taskListItem.field_name,
                    })}
                    open={true}
                    setOpen={setDeleteDialogOpen}
                    onConfirm={() => handleDeleteTaskRequest(taskListItem.id)}
                    type='delete-task'
                >
                    {t('tasks:Tasks.areYouSure')}
                </ConfirmDialog>
            )}
        </>
    )
}
