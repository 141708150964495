import { useState } from 'react'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import { useTaskActions, useAlertActions } from '../../_actions'
import { CreateTaskButton, CultivarList, BedExtensionSlider } from '..'
import RepeatOneIcon from '@mui/icons-material/RepeatOne'
import RepeatOneOnIcon from '@mui/icons-material/RepeatOneOn'
import { taskQuery, drawSettingsAtom, taskSettingsState } from '../../_state'
import { Stack, Chip, Button } from '@mui/material'
import SwapVertIcon from '@mui/icons-material/SwapVert'
import { H2LFieldJson, Task } from '../../types'
import { useTranslation } from 'react-i18next'

export const CultivarsListMobile = ({ h2lFieldJson }: { h2lFieldJson: H2LFieldJson }) => {
    // Actions
    const { t } = useTranslation('tasks')
    const alertActions = useAlertActions()
    const taskActions = useTaskActions()

    // State
    const [btnLoading, setBtnLoading] = useState(false)
    const setTaskSettings = useSetRecoilState(taskSettingsState)
    const { bed_extension, radius, method, turn, direction, repeat, pick_up_point } = useRecoilValue(taskSettingsState)

    // Recoil
    const setTask = useSetRecoilState(taskQuery)
    const setDrawSettings = useSetRecoilState(drawSettingsAtom)

    const handleCreateTask = (selectedBedIDs: number[]) => {
        if (selectedBedIDs && h2lFieldJson) {
            setBtnLoading(true)
            return taskActions
                .create({
                    crop_id: h2lFieldJson.crop_id,
                    tulip_bed_ids: selectedBedIDs,
                    bed_extension: bed_extension,
                    radius: radius,
                    method: method,
                    turn: turn,
                    direction: direction,
                    repeat: repeat,
                    pick_up_point: pick_up_point,
                })
                .then((task: Task) => {
                    setTask({ data: task, isLoading: false })
                    alertActions.success(t('tasks:CultivarsListMobile.createTaskOk'))
                })
                .catch(() => {
                    setTask({ data: null, isLoading: false })
                    alertActions.error(t('tasks:CultivarsListMobile.errorCreateTask'))
                })
                .finally(() => setBtnLoading(false))
        }
    }

    const render = () => {
        return (
            <>
                <Stack spacing={1}>
                    <Stack direction={'row'} display={'flex'} justifyContent={'center'} spacing={0.5}>
                        <Stack spacing={1}>
                            <Chip
                                label={t('tasks:CultivarsListMobile.plantedArea')}
                                color={method === 'planted-area' ? 'secondary' : 'primary'}
                                onClick={() => {
                                    setTaskSettings((prev) => ({
                                        ...prev,
                                        method: 'planted-area',
                                    }))
                                    setDrawSettings({ plantedArea: true })
                                }}
                            />
                            <Chip
                                label={t('tasks:CultivarsListMobile.bedExtension')}
                                color={method == 'bed-extension' ? 'secondary' : 'primary'}
                                onClick={() => {
                                    setTaskSettings((prev) => ({
                                        ...prev,
                                        method: 'bed-extension',
                                    }))
                                    setDrawSettings({ plantedArea: false })
                                }}
                            />
                        </Stack>
                        <Stack spacing={1}>
                            <Chip
                                label={t('tasks:CultivarsListMobile.threePoint')}
                                color={turn === 'three-point' ? 'secondary' : 'primary'}
                                onClick={() => {
                                    setTaskSettings((prev) => ({
                                        ...prev,
                                        turn: 'three-point',
                                    }))
                                    setDrawSettings({ plantedArea: true })
                                }}
                            />
                            <Chip
                                label={t('tasks:CultivarsListMobile.omega')}
                                color={turn == 'omega' ? 'secondary' : 'primary'}
                                onClick={() => {
                                    setTaskSettings((prev) => ({
                                        ...prev,
                                        turn: 'omega',
                                    }))
                                    setDrawSettings({ plantedArea: false })
                                }}
                            />
                        </Stack>
                        <Button
                            onClick={() => {
                                setTaskSettings((prev) => ({
                                    ...prev,
                                    direction: prev.direction === 'down' ? 'up' : 'down',
                                }))
                            }}
                        >
                            <SwapVertIcon fontSize='large' color={'secondary'} sx={{}} />
                        </Button>
                        <Button
                            onClick={() => {
                                setTaskSettings((prev) => ({
                                    ...prev,
                                    repeat: prev.repeat === 0 ? 1 : 0,
                                }))
                            }}
                        >
                            {repeat === 0 ? (
                                <RepeatOneIcon fontSize='large' color='secondary' />
                            ) : (
                                <RepeatOneOnIcon fontSize='large' color='secondary' />
                            )}
                        </Button>
                    </Stack>
                    <CreateTaskButton handleCreateTask={handleCreateTask} btnLoading={btnLoading} />
                </Stack>
                {method === 'bed-extension' && <BedExtensionSlider />}
                <CultivarList fieldID={h2lFieldJson.field_id} cropID={h2lFieldJson.crop_id} />
            </>
        )
    }
    return render()
}
