import { Routes, Route } from 'react-router-dom'
import TaskBar from './TaskBar'
import IconTabs from './IconTabs'
import TreatmentTab from './TreatmentTab'
import CamerasTab from './CamerasTab'
import { DriveTab } from './DriveTab'
import Box from '@mui/material/Box'

export const TabManager = () => {
    return (
        <>
            <Box
                sx={{
                    flex: 1,
                    position: 'relative',
                    left: '0',
                    right: '0',
                    top: '0',
                    bottom: '0',
                    overflow: 'auto',
                }}
            >
                <Routes>
                    <Route path='/drive' element={<DriveTab />} />
                    <Route path='/treatment' element={<TreatmentTab />} />
                    <Route path='/cameras' element={<CamerasTab />} />
                </Routes>
            </Box>
            <Box>
                <TaskBar />
                <IconTabs />
            </Box>
        </>
    )
}
