import { useEffect, useState } from 'react'
import { useSetRecoilState, useRecoilState, useResetRecoilState } from 'recoil'
import { h2lFieldJsonAtom, taskQuery, taskSettingsState } from '../_state'
import { CultivarsDrawer } from './'
import { useOutletContext, useSearchParams } from 'react-router-dom'
import { Box, Fab, Stack, Button } from '@mui/material'
import SwapVertIcon from '@mui/icons-material/SwapVert'
import RepeatOneIcon from '@mui/icons-material/RepeatOne'
import RepeatOneOnIcon from '@mui/icons-material/RepeatOneOn'
import ListIcon from '@mui/icons-material/List'
import { H2LFieldJson } from '../types'
import { useBedIDsSelector } from '../_helpers'
import { LocationPin } from '../_components'
import { PickUpPointPin } from '../_components/PickUpPointPin'
export { TulipBedsView }

interface OutletContext {
    h2lFieldJson: H2LFieldJson
    cropID: number
}
function TulipBedsView() {
    const [searchParams] = useSearchParams()

    const [cultivarDrawerOpen, setCultivarDrawerOpen] = useState(true)

    const { h2lFieldJson } = useOutletContext<OutletContext>()
    const setH2lFieldJson = useSetRecoilState(h2lFieldJsonAtom)
    const [taskSettings, setTaskSettings] = useRecoilState(taskSettingsState)
    const resetTaskSettings = useResetRecoilState(taskSettingsState)
    const setTask = useSetRecoilState(taskQuery)

    const bedIDsSelector = useBedIDsSelector()
    const handleCultivarDrawerOpen = (val: boolean) => setCultivarDrawerOpen(val)
    const [showLocationPin, setShowLocationPin] = useState(false)

    const togglePickUpPoint = () => {
        if (showLocationPin) {
            setTaskSettings((prev) => ({
                ...prev,
                pick_up_point: null,
            }))
        }
        setShowLocationPin(!showLocationPin)
    }

    // Set name of tab
    useEffect(() => {
        document.title = `${h2lFieldJson.name} - H2L Selector`
        setH2lFieldJson(h2lFieldJson)
        return () => {
            document.title = 'H2L Selector'
            setH2lFieldJson(null)
            setTask({ data: null, isLoading: false })
            resetTaskSettings()
            bedIDsSelector.resetSelectedBedIDs()
        }
    }, [h2lFieldJson])

    return (
        <>
            <Box sx={{ position: 'relative' }}>{cultivarDrawerOpen && <CultivarsDrawer />}</Box>
            <Button
                sx={{
                    mt: 2,
                    mr: 2,
                    zIndex: 1000,
                    position: 'absolute',
                    top: 60,
                    right: 0,
                    backgroundColor: 'white',
                    color: 'black',
                    '&:hover': {
                        backgroundColor: 'white',
                    },
                }}
                variant='contained'
                size='large'
                startIcon={<LocationPin disabled={!showLocationPin} scale={1} />}
                onClick={togglePickUpPoint}
            >
                Pick Up Point
            </Button>
            <PickUpPointPin isVisible={showLocationPin} headland={h2lFieldJson.headland} />
            <Stack
                sx={{
                    '& > :not(style)': { m: 1 },
                    position: 'absolute',
                    bottom: '20px',
                    right: '1%',
                }}
            >
                {searchParams.get('drawerView') === 'task-planner' && (
                    <>
                        <Fab
                            id='repeat'
                            onClick={() => {
                                setTaskSettings((prev) => ({
                                    ...prev,
                                    repeat: prev.repeat === 0 ? 1 : 0,
                                }))
                            }}
                        >
                            {taskSettings.repeat === 0 ? (
                                <RepeatOneIcon fontSize='large' />
                            ) : (
                                <RepeatOneOnIcon fontSize='large' />
                            )}
                        </Fab>
                        <Fab
                            id='change-direction'
                            onClick={() => {
                                setTaskSettings((prev) => ({
                                    ...prev,
                                    direction: prev.direction === 'down' ? 'up' : 'down',
                                }))
                            }}
                        >
                            <SwapVertIcon fontSize='large' />
                        </Fab>
                    </>
                )}
                <Fab
                    id='tulip-drawer-btn'
                    onClick={() => {
                        cultivarDrawerOpen === true ? handleCultivarDrawerOpen(false) : handleCultivarDrawerOpen(true)
                    }}
                >
                    <ListIcon fontSize='large' />
                </Fab>
            </Stack>
        </>
    )
}
