import { useRef, useEffect } from 'react'
import { useRecoilValue } from 'recoil'
import { mapAtom, subframePositionAtom } from '../_state'

import { rotate, headingToCartesian, getCartesian2GpsConverter } from '../_helpers/geometry'

const P: [number, number][] = [
    [0, 0],
    [2.598, -1.499],
    [2.598, 1.499],
]

function calculateMachineFOVTriangle(
    machineGps: [number, number],
    machineHeading: number,
): google.maps.LatLngLiteral[] {
    const machineHeadingCartesian = headingToCartesian(machineHeading)
    const converter = getCartesian2GpsConverter(machineGps)

    return P.map((point) => {
        const gpsPoint = converter(rotate(point, machineHeadingCartesian))
        return { lat: gpsPoint[0], lng: gpsPoint[1] }
    })
}

export function RobotFOVTriangle({ color }: { color: string }): null {
    const map = useRecoilValue(mapAtom)
    const subframePosition = useRecoilValue(subframePositionAtom)
    const robotFOVTriangleMarker = useRef<google.maps.Polygon>(
        new google.maps.Polygon({
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillOpacity: 0.5,
        }),
    )
    useEffect(() => {
        return () => {
            robotFOVTriangleMarker.current.setMap(null)
        }
    }, [])

    if (
        subframePosition &&
        subframePosition.lat &&
        subframePosition.lon &&
        subframePosition.heading &&
        subframePosition.qual == 4
    ) {
        robotFOVTriangleMarker.current.setOptions({
            strokeColor: color,
            fillColor: color,
            map: map,
            paths: calculateMachineFOVTriangle([subframePosition.lat, subframePosition.lon], subframePosition.heading),
        })
    } else {
        robotFOVTriangleMarker.current.setMap(null)
    }

    return null
}
