import { atom } from 'recoil'
import { MSU } from '../types'
import { Socket } from 'socket.io-client'

const machineModelAtom = atom<'S180' | 'S225' | 'IC300' | null>({
    key: 'machine_model',
    default: null,
})

const machineMSUAtom = atom<MSU | null>({
    key: 'machine_msu',
    default: null,
})

const machineNameAtom = atom<string | null>({
    key: 'machine_name',
    default: null,
})

const machineSocketAtom = atom<Socket | null>({
    key: 'machine_socket',
    default: null,
    dangerouslyAllowMutability: true,
})

export { machineNameAtom, machineSocketAtom, machineModelAtom, machineMSUAtom }
