import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import commonEN from './locales/en/common.json'
import machinesEN from './locales/en/machines.json'
import fieldsEN from './locales/en/fields.json'
import usersEN from './locales/en/users.json'
import tasksEN from './locales/en/tasks.json'
import messagesEN from './locales/en/messages.json'
import msuEN from './locales/en/msu.json'
import commonNL from './locales/nl/common.json'
import machinesNL from './locales/nl/machines.json'
import fieldsNL from './locales/nl/fields.json'
import usersNL from './locales/nl/users.json'
import tasksNL from './locales/nl/tasks.json'
import messagesNL from './locales/nl/messages.json'
import msuNL from './locales/nl/msu.json'
import { useUserActions } from '../_actions'
import UserService from '../services/UserService'

const resources = {
    en: {
        common: commonEN,
        machines: machinesEN,
        fields: fieldsEN,
        users: usersEN,
        tasks: tasksEN,
        messages: messagesEN,
        msu: msuEN,
    },
    nl: {
        common: commonNL,
        machines: machinesNL,
        fields: fieldsNL,
        users: usersNL,
        tasks: tasksNL,
        messages: messagesNL,
        msu: msuNL,
    },
}

export const init = () => {
    const storedLanguage = localStorage.getItem('i18nextLng')
    if (i18n.isInitialized) {
        if (storedLanguage && i18n.language !== storedLanguage) {
            i18n.changeLanguage(storedLanguage)
        }
        return
    }

    i18n
        .use(initReactI18next)
        .init({
            lng: UserService.getLocale(),
            fallbackLng: 'nl',
            interpolation: {
                escapeValue: false,
            },
            resources: resources
        })

    if (storedLanguage) {
        i18n.changeLanguage(storedLanguage)
    }
    return true
}

export const useChangeLanguage = () => {
    const userActions = useUserActions()

    const changeLanguage = async (newLanguage?: string) => {
        try {
            // for key binding
            if (!newLanguage) {
                const localStorageLang = localStorage.getItem('i18nextLng')
                if (localStorageLang) {
                    localStorageLang === 'nl' ? newLanguage = 'en' : newLanguage = 'nl'
                } else {
                    console.error('No language found on local storage, using NL')
                    newLanguage = 'nl'
                }
            }
            await userActions
                .changeLocale(newLanguage)
                .then((res: { message: string, locale: string }) => {
                    i18n.changeLanguage(res.locale)
                    localStorage.setItem('i18nextLng', res.locale)
                })
                .catch(() => {
                    console.error('Failed to update user locale: Unexpected response status')
                })
        } catch (error) {
            console.error('Failed to update user locale:', error)
        }
    }

    return changeLanguage
}

export default i18n