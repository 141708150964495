import React from 'react'
import { useRecoilValue } from 'recoil'
import { Box, Divider, Button, List, Typography, ListItemButton, ListItemText, Stack, Collapse } from '@mui/material'
import SpaIcon from '@mui/icons-material/Spa'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { useBedIDsSelector } from '../_helpers'
import { cultivarListSelector, selectedBedIDsAtom } from '../_state'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { CultivarGroup } from '../types'
import { useNavigate, useResolvedPath } from 'react-router'
import { yellow, blue } from '@mui/material/colors'

interface IconTooltipProps {
    cGroup: CultivarGroup
}

const IconTooltip = ({ cGroup }: IconTooltipProps): JSX.Element | null => {
    if (cGroup?.cultivar?.toLowerCase()) {
        return (
            <CheckCircleOutlineIcon
                sx={{
                    position: 'absolute',
                    top: -2,
                    left: 22,
                    fontSize: 18,
                    color: 'green',
                }}
            />
        )
    } else {
        return (
            <ErrorOutlineIcon
                sx={{
                    position: 'absolute',
                    top: -2,
                    left: 22,
                    fontSize: 18,
                    color: 'red',
                }}
            />
        )
    }
}

const MatchingHelp = ({
    fieldID,
    cropID,
    cGroup,
    onClose,
}: {
    fieldID?: number
    cropID?: number
    cGroup: CultivarGroup
    onClose: () => void
}): JSX.Element => {
    const navigate = useNavigate()
    const url = useResolvedPath('').pathname

    return (
        <Stack sx={{ pl: 7, pr: 2, pb: 2 }}>
            <Stack>
                <Stack direction={'row'} spacing={1}>
                    {cGroup?.cultivar?.toLowerCase() ? (
                        <>
                            <CheckCircleOutlineIcon sx={{ color: 'green' }} />
                            <Stack>
                                <Typography variant='caption'>{`Cultivar is recognized as ${cGroup.cultivar}.`}</Typography>
                                <Typography variant='caption'>{'Do you want to change it?'}</Typography>
                            </Stack>
                        </>
                    ) : (
                        <>
                            <ErrorOutlineIcon sx={{ color: 'red' }} />
                            <Stack>
                                <Typography variant='caption'>{`No cultivar named ${cGroup.name} found.`}</Typography>
                                <Typography variant='caption'>{'Do you want to choose the cultivar name?'}</Typography>
                            </Stack>
                        </>
                    )}
                </Stack>
                <Stack direction={'row'} spacing={1} justifyContent={'end'} sx={{ mt: 1 }}>
                    <Button
                        size='small'
                        variant='contained'
                        color='secondary'
                        onClick={(event) => {
                            onClose()
                            event.stopPropagation()
                        }}
                    >
                        No
                    </Button>
                    <Button
                        onClick={() => navigate(`/fields/${fieldID}/crops/${cropID}/edit/cultivar-matching`)}
                        variant='contained'
                        size='small'
                    >
                        Yes
                    </Button>
                </Stack>
            </Stack>
        </Stack>
    )
}

function CultivarList({ fieldID, cropID }: { fieldID?: number; cropID?: number }) {
    const [helpExpandedItems, setHelpExpandedItems] = React.useState<Set<number>>(new Set())
    const [listExpandedItems, setListExpandedItems] = React.useState<Set<number>>(new Set())

    const bedIDsSelector = useBedIDsSelector()
    const cultivarList = useRecoilValue(cultivarListSelector)
    const selectedBedIDs = useRecoilValue(selectedBedIDsAtom)

    const handleExpandClick = (group_id: number) => {
        setListExpandedItems((prev) => {
            if (prev.has(group_id)) {
                const newSet = new Set(prev)
                newSet.delete(group_id)
                return newSet
            } else {
                return new Set(prev).add(group_id)
            }
        })
    }
    const handleHelpClick = (group_id: number, event: React.MouseEvent) => {
        event.stopPropagation()
        setHelpExpandedItems((prev) => {
            if (prev.has(group_id)) {
                const newSet = new Set(prev)
                newSet.delete(group_id)
                return newSet
            } else {
                return new Set(prev).add(group_id)
            }
        })
    }

    const handleSelectAll = (beds: Array<any>) => bedIDsSelector.addMany(beds.map((b) => b.id))
    const handleDeselectAll = (beds: Array<any>) => bedIDsSelector.removeMany(beds.map((b) => b.id))

    return (
        <Box sx={{ width: '100%' }}>
            {cultivarList !== null ? (
                <List aria-label='cultivar list'>
                    {Object.keys(cultivarList).map((index) => {
                        const item: CultivarGroup = cultivarList[index]
                        const isHelpExpanded = helpExpandedItems.has(item.group_id)
                        const isListExpanded = listExpandedItems.has(item.group_id)
                        const selectedBedsCount = item.beds.filter((bed) => selectedBedIDs.has(bed.id)).length
                        const areAllBedsSelected = selectedBedsCount === item.beds.length
                        const someBedsSelected = selectedBedsCount > 0 && selectedBedsCount < item.beds.length

                        return (
                            <div key={index}>
                                <ListItemButton
                                    onClick={() =>
                                        areAllBedsSelected ? handleDeselectAll(item.beds) : handleSelectAll(item.beds)
                                    }
                                    selected={areAllBedsSelected || someBedsSelected}
                                    sx={{
                                        borderRadius: '4px',
                                        margin: '4px 0', // Add vertical spacing
                                        '&.Mui-selected': {
                                            backgroundColor: areAllBedsSelected ? blue[300] : yellow[300],
                                            color: 'black',
                                            '& .MuiListItemText-primary, & .MuiListItemText-secondary': {
                                                color: 'black',
                                            },
                                            '&:hover': {
                                                backgroundColor: areAllBedsSelected ? blue[100] : yellow[100],
                                            },
                                        },
                                        '&:hover': {
                                            backgroundColor: areAllBedsSelected
                                                ? blue[100]
                                                : someBedsSelected
                                                ? yellow[100]
                                                : 'rgba(0, 0, 0, 0.04)',
                                        },
                                    }}
                                >
                                    <Stack direction={'row'} style={{ position: 'relative', width: '100%' }}>
                                        <Stack justifyContent={'center'} sx={{ mr: 0.5 }}>
                                            <Button
                                                sx={{ minWidth: '38px' }}
                                                onClick={(e) => handleHelpClick(item.group_id, e)}
                                            >
                                                <SpaIcon sx={{ color: item.strokeColor }} />
                                                <IconTooltip cGroup={item} />
                                            </Button>
                                        </Stack>
                                        <Divider orientation='vertical' variant='middle' flexItem />
                                        <Box sx={{ ml: 1, flex: 1 }}>
                                            {item.cultivar ? (
                                                <ListItemText
                                                    primary={`${item.cultivar} 📏 ${item.size}`}
                                                    secondary={`${item.name}`}
                                                />
                                            ) : (
                                                <ListItemText primary={`${item.name} 📏 ${item.size}`} />
                                            )}
                                        </Box>
                                        <Box
                                            sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                                            onClick={(e) => {
                                                e.stopPropagation() // Prevents the click from triggering ListItemButton's onClick
                                                handleExpandClick(item.group_id)
                                            }}
                                        >
                                            {isListExpanded ? <ExpandLess /> : <ExpandMore />}
                                        </Box>
                                    </Stack>
                                </ListItemButton>

                                {/* Matching Help Collapse */}
                                <Collapse in={isHelpExpanded} timeout='auto' unmountOnExit>
                                    <MatchingHelp
                                        fieldID={fieldID}
                                        cropID={cropID}
                                        cGroup={item}
                                        onClose={() =>
                                            setHelpExpandedItems((prev) => {
                                                const newSet = new Set(prev)
                                                newSet.delete(item.group_id)
                                                return newSet
                                            })
                                        }
                                    />
                                </Collapse>

                                {/* Beds List Collapse */}
                                <Collapse in={isListExpanded} timeout='auto' unmountOnExit>
                                    <List component='div' disablePadding>
                                        {item.beds.map((bed, index) => (
                                            <ListItemButton
                                                key={bed.id}
                                                sx={{
                                                    '&.Mui-selected': {
                                                        backgroundColor: areAllBedsSelected ? blue[300] : yellow[300],
                                                        color: 'black',
                                                        '& .MuiListItemText-primary': {
                                                            color: 'black',
                                                        },
                                                        '&:hover': {
                                                            backgroundColor: areAllBedsSelected
                                                                ? blue[100]
                                                                : yellow[100],
                                                        },
                                                    },
                                                    '&:hover': {
                                                        backgroundColor: areAllBedsSelected
                                                            ? blue[100]
                                                            : selectedBedIDs.has(bed.id)
                                                            ? yellow[100]
                                                            : 'rgba(0, 0, 0, 0.04)',
                                                    },
                                                    m: 0.5,
                                                    pl: 7,
                                                    borderRadius: '2px',
                                                }}
                                                selected={selectedBedIDs.has(bed.id)}
                                                onClick={(e) => {
                                                    e.stopPropagation()
                                                    bedIDsSelector.toggleSelected(bed.id)
                                                }}
                                            >
                                                <ListItemText
                                                    primary={`Bed ${index + 1} - ${bed.properties.length}m`}
                                                    sx={{
                                                        '& .MuiListItemText-primary': {
                                                            fontSize: '0.9rem',
                                                        },
                                                    }}
                                                />
                                            </ListItemButton>
                                        ))}
                                    </List>
                                </Collapse>
                            </div>
                        )
                    })}
                </List>
            ) : (
                <List component='nav' aria-label='empty cultivar list'>
                    <ListItemButton>
                        <ListItemText primary='No tulip beds found for this field.' />
                    </ListItemButton>
                </List>
            )}
        </Box>
    )
}

export { CultivarList }
