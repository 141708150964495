import React from 'react'
import { useParams, Outlet } from 'react-router-dom'
import { Box } from '@mui/material'
import { Map } from '../_components'
import { CropTask } from '../gmap/CropTask'
import { useCropActions } from '../_actions'
import { styles } from '../styles'

export { Controller }

function Controller() {
    // eslint-disable-next-line camelcase
    const { crop_id } = useParams<Record<string, string>>()
    // const fieldID = parseInt(field_id)
    const cropID = parseInt(crop_id)
    // const fieldFunctions = useFieldFunctions()
    const [h2lFieldJson, setH2lFieldJson] = React.useState(null)
    const cropActions = useCropActions()

    // 🚨 Breaks CropEditorView field rename functionality
    // const handleKeyDown = (event: KeyboardEvent) => {
    //   event.preventDefault()
    //   if (event.key === '4' && event.ctrlKey) {
    //     navigate(`/fields/${fieldID}/crops/${cropID}/beds/`)
    //   }
    //   if (event.key === '2' && event.ctrlKey && fieldID) {
    //     fieldFunctions.reparse(fieldID)
    //   }
    //   if (event.key === 'e' && event.ctrlKey && fieldID) {
    //     navigate(`/fields/${fieldID}/crops/${cropID}/edit`)
    //   }
    //   // if (event.key === 'F8' && event.ctrlKey) {
    //   // keyboard.detectTreatables(fieldID, cropID)
    //   // }
    // }

    // React.useEffect(() => {
    //   if (UserService.hasRole(['admin'])) {
    //     window.addEventListener('keydown', handleKeyDown)
    //   }
    //   return () => {
    //     if (UserService.hasRole(['admin'])) {
    //       window.removeEventListener('keydown', handleKeyDown)
    //     }
    //   }
    // })

    React.useEffect(() => {
        cropActions.getCropByIdWithTulibBeds(cropID).then(setH2lFieldJson)
    }, [crop_id])

    return (
        <>
            <Box sx={[styles.MobileView.BoxOuter]}>
                <Map>
                    <CropTask />
                    {h2lFieldJson && <Outlet context={{ h2lFieldJson, cropID }} />}
                </Map>
            </Box>
        </>
    )
}
