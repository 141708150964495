import { useEffect } from 'react';
import UserService from '../services/UserService';
import { useImpersonateUser } from './useImpersonateUser';
import { useChangeLanguage } from '../i18n/i18n';

export const useKeyBindings = () => {
    const impersonate = useImpersonateUser();
    const changeLanguage = useChangeLanguage();

    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.altKey) {
            switch (event.key) {
                case 'i':
                    impersonate.toggle();
                    break;
                case 'l':
                    changeLanguage();
                    break;
                default:
                    break;
            }
        }
    };

    useEffect(() => {
        if (UserService.hasRole(['admin'])) {
            window.addEventListener('keydown', handleKeyDown);
        }

        return () => {
            if (UserService.hasRole(['admin'])) {
                window.removeEventListener('keydown', handleKeyDown);
            }
        };
    }, [impersonate, changeLanguage]);
};