import { FullscreenDialog } from './FullscreenDialog'

export const FullscreenIFrame = ({
    title,
    url,
    handleClose,
}: {
    title: string
    url: string
    handleClose: () => void
}) => (
    <FullscreenDialog
        toolbarTitle={title}
        dialogContent={<iframe width='100%' height='100%' src={url} title={title}></iframe>}
        dialogContentStyle={{ p: 0, overflow: 'hidden' }}
        handleClose={handleClose}
    />
)
