import { MultiPoint, TulipMemoryBatch, GeneralBatch } from '../types'

type Point = [number, number]

function meterPerDegree(lat: number) {
    // Calculate conversion factor for both latitude and longitude
    // Uses conversion: https://en.wikipedia.org/wiki/Geographic_coordinate_system
    // Input latitude estimation
    const phi = (Math.PI / 180) * lat
    const meterPerDegreeLat =
        111132.92 - 559.82 * Math.cos(2 * phi) + 1.175 * Math.cos(4 * phi) - 0.0023 * Math.cos(6 * phi)

    const meterPerDegreeLon = 111412.84 * Math.cos(phi) - 93.5 * Math.cos(3 * phi) + 0.118 * Math.cos(5 * phi)

    return [meterPerDegreeLat, meterPerDegreeLon]
}

function gps2Cartesian(gps: number[], refgps: number[]) {
    // Input: gps - GPS position to convert
    //        refgps - reference point
    //        z - optional parameter for z-coordinate (default is 0)
    // Returns: Cartesian coordinates in meters w.r.t. refgps as [east, north, z]

    const [mpdLat, mpdLon] = meterPerDegree(refgps[1])
    const east = (gps[0] - refgps[0]) * mpdLon
    const north = (gps[1] - refgps[1]) * mpdLat
    return [east, -north] // - north so it is consistent with top left coord system of Pixi
}

function cartesian2Gps(xy: [number, number], refGps: [number, number]): [number, number] {
    const [mpdLat, mpdLon] = meterPerDegree(refGps[0])

    return [xy[1] / mpdLat + refGps[0], xy[0] / mpdLon + refGps[1]]
}

function getCartesian2GpsConverter(refGps: [number, number]): (xy: [number, number]) => [number, number] {
    const [mpdLat, mpdLon] = meterPerDegree(refGps[0])

    return (xy: [number, number]): [number, number] => [xy[1] / mpdLat + refGps[0], xy[0] / mpdLon + refGps[1]]
}

function headingToCartesian(angle: number): number {
    // Convert a heading to a Cartesian angle between 0 and 2*pi
    // Heading: zero = "up", positive clockwise, units in degrees
    // Cartesian angle: zero = "right", positive counterclockwise, units in radians
    return (((90 - angle) % 360) * Math.PI) / 180 // Cartesian machine direction
}

function rotate(xy: [number, number], alpha: number): [number, number] {
    // alpha radians
    const c = Math.cos(alpha)
    const s = Math.sin(alpha)
    return [c * xy[0] - s * xy[1], s * xy[0] + c * xy[1]]
}

function calculateTranslationVector(points: Point[]): Point {
    let minX = Infinity
    let minY = Infinity

    for (const point of points) {
        const [x, y] = point
        if (x < minX) {
            minX = x
        }
        if (y < minY) {
            minY = y
        }
    }

    return [Math.abs(minX), Math.abs(minY)]
}

// Function to apply the translation to the points
function translatePointsToPositive(points: Point[], translationVector: Point): Point[] {
    return points.map(([x, y]) => [x + translationVector[0], y + translationVector[1]])
}

function processTulipMemoryBatch(tulipMemoryBatch: TulipMemoryBatch): GeneralBatch {
    // Get the first point (coordinates) from the TulipMemoryBatch
    const firstPoint = tulipMemoryBatch.geometry?.coordinates[0] || [0, 0]

    // Create a reference GPS object
    const refGPS = {
        lon: firstPoint[0],
        lat: firstPoint[1],
        heading: 0, // Set heading as needed
    }

    const translatedCoordinates: [number, number][] = (tulipMemoryBatch.geometry?.coordinates || []).map((point) => {
        const cartesian = gps2Cartesian(point, [refGPS.lon, refGPS.lat, refGPS.heading])
        return [cartesian[0], cartesian[1]]
    })

    let geometry: MultiPoint | null = null
    let points: [number, number][] = []
    let translationVector: [number, number] = [0, 0]

    if (translatedCoordinates.length) {
        translationVector = calculateTranslationVector(translatedCoordinates)
        points = translatePointsToPositive(translatedCoordinates, translationVector)

        geometry = {
            type: 'MultiPoint',
            coordinates: translatedCoordinates,
        }
    }

    const newTulipMemoryBatch: TulipMemoryBatch = {
        type: 'Feature',
        geometry: geometry,
        properties: tulipMemoryBatch.properties,
    }

    const generalBatch: GeneralBatch = {
        tulipMemoryBatch, // Original batch
        refGPS,
        newTulipMemoryBatch, // Translated batch
        points,
        translationVector,
    }

    return generalBatch
}

export {
    meterPerDegree,
    gps2Cartesian,
    cartesian2Gps,
    rotate,
    processTulipMemoryBatch,
    headingToCartesian,
    getCartesian2GpsConverter,
}
