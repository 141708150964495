import { Box, Tabs, Tab } from '@mui/material'
import { styles } from '../../styles'
import { IndicatorIcon } from '../IndicatorIcon'
import { useNavigate } from 'react-router-dom'

export default function IconTabs() {
    const navigate = useNavigate()
    const tabRoutes = ['drive', 'treatment', 'cameras']
    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        }
    }
    const currentTab = tabRoutes.findIndex((route) => location.pathname.endsWith(route))

    return (
        <Box sx={[styles.IconTabsBox]}>
            <Tabs
                indicatorColor={'secondary'}
                value={currentTab}
                onChange={(e, index) => navigate(`./${tabRoutes[index]}`)}
                variant='fullWidth'
            >
                {tabRoutes.map((t, i) => {
                    return <Tab key={t} icon={<IndicatorIcon type={t} />} {...a11yProps(i)} />
                })}
            </Tabs>
        </Box>
    )
}
