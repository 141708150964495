import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { LocationPin, Map, RobotIcon } from '../_components'
import { Box, Button } from '@mui/material'
import { CropTask } from '../gmap/CropTask'
import { CultivarsListMobile } from '../_components/mobile/CultivarsListMobile'
import ArrowBack from '@mui/icons-material/ArrowBack'
import { useCropActions } from '../_actions'
import { useSetRecoilState, useRecoilValue, useRecoilState, useResetRecoilState } from 'recoil'
import { machineNameAtom, h2lFieldJsonAtom, taskQuery, taskSettingsState } from '../_state'
import { PickUpPointPin } from '../_components/PickUpPointPin'
import { useTranslation } from 'react-i18next'

export const MachineCreateTask = () => {
    const { t } = useTranslation(['machines'])
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const cropID = parseInt(searchParams.get('crop-id'))
    const machineName = useRecoilValue(machineNameAtom)
    const setTask = useSetRecoilState(taskQuery)
    const resetTask = useResetRecoilState(taskQuery)
    const setTaskSettings = useSetRecoilState(taskSettingsState)
    const resetTaskSettings = useResetRecoilState(taskSettingsState)
    const setCrop = useSetRecoilState(h2lFieldJsonAtom)
    const [field, setField] = useRecoilState(h2lFieldJsonAtom)
    const cropActions = useCropActions()
    const [showLocationPin, setShowLocationPin] = useState(false)

    const togglePickUpPoint = () => {
        if (showLocationPin) {
            setTaskSettings((prev) => ({
                ...prev,
                pick_up_point: null,
            }))
        }
        setShowLocationPin(!showLocationPin)
    }

    useEffect(() => {
        resetTask()
        cropActions.getCropByIdWithTulibBeds(cropID).then(setField)

        return () => {
            setTask({ data: null, isLoading: false })
            setCrop(null)
            cropActions.resetTulipBeds()
            resetTaskSettings()
        }
    }, [machineName])

    if (!machineName || !field) return null
    return (
        <>
            <Button
                sx={{
                    mt: 2,
                    ml: 2,
                    zIndex: 1000,
                    position: 'absolute',
                    top: 0,
                    left: 0,
                }}
                variant='contained'
                size='large'
                startIcon={<ArrowBack />}
                onClick={() => navigate(`/m/${machineName}/drive`)}
            >
                {machineName}
            </Button>
            <Button
                sx={{
                    mt: 2,
                    mr: 2,
                    zIndex: 1000,
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    backgroundColor: 'white',
                    color: 'black',
                    '&:hover': {
                        backgroundColor: 'white',
                    },
                }}
                variant='contained'
                size='large'
                startIcon={<LocationPin disabled={!showLocationPin} scale={1} />}
                onClick={togglePickUpPoint}
            >
                {t('machines:MachineCreateTask.pickupPoint')}
            </Button>
            <Box
                sx={{
                    flex: 'auto',
                    position: 'relative',
                    display: 'flex',
                    alignItems: 'flex-end',
                    flexDirection: 'row-reverse',
                }}
            >
                <Map>
                    <PickUpPointPin isVisible={showLocationPin} headland={field.headland} />
                    <RobotIcon />
                    <CropTask />
                </Map>
            </Box>
            <Box sx={{ m: 1, height: '1%', flex: 'auto', position: 'relative', overflow: 'auto' }}>
                <CultivarsListMobile h2lFieldJson={field} />
            </Box>
        </>
    )
}
