import { useState, MouseEvent } from 'react'
import UserService from '../services/UserService'
import { RoleIcon } from './RoleIcon'
import Person from '@mui/icons-material/Person'
import KeyIcon from '@mui/icons-material/Key'
import {
    Stack,
    ListItemText,
    Switch,
    IconButton,
    Menu,
    MenuItem,
    Tooltip,
    ListItemIcon,
    Divider,
    Typography,
} from '@mui/material/'
import NumbersIcon from '@mui/icons-material/Numbers'
import RefreshIcon from '@mui/icons-material/Refresh'
import LanguageIcon from '@mui/icons-material/Language'
import { useImpersonateUser } from '../_helpers'
import { LanguageSelector } from './LanguageSelector'

export const AppBarUserMenu = () => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
    const impersonate = useImpersonateUser()
    const handleMenu = (event: MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget)
    const handleClose = () => setAnchorEl(null)
    const handleRefreshOnClick = () => window.location.reload()
    const impersonateUsers = useImpersonateUser()
    const handleToggleImpersonate = () => {
        impersonate.toggle()
        handleClose()
    }
    const showImpersonate = UserService.hasRole(['admin']) || impersonateUsers.isImpersonating() === true

    return (
        <>
            <Tooltip title='Account'>
                <IconButton size='large' onClick={handleMenu} color='inherit'>
                    <RoleIcon role={UserService.role()} />
                </IconButton>
            </Tooltip>
            <Menu
                id='menu-appbar'
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem>
                    <ListItemIcon>
                        <Person fontSize='small' />
                    </ListItemIcon>
                    {UserService.getUsername()}
                </MenuItem>
                <MenuItem>
                    <ListItemIcon>
                        <KeyIcon fontSize='small' />
                    </ListItemIcon>
                    {UserService.role()[0].toUpperCase() + UserService.role().slice(1)}
                </MenuItem>
                <MenuItem>
                    <ListItemIcon>
                        <LanguageIcon fontSize='small' />
                    </ListItemIcon>
                    <LanguageSelector onChange={handleClose} />
                </MenuItem>
                <MenuItem>
                    <Stack direction={'row'} spacing={0} alignItems={'center'}>
                        <ListItemIcon>
                            <NumbersIcon fontSize='small' />
                        </ListItemIcon>
                        <Typography>{'v2024.4.24'}</Typography>
                        <IconButton color='success' size='small' onClick={handleRefreshOnClick}>
                            <RefreshIcon fontSize='small' sx={{ pt: 0, pb: 0, pl: 1, pr: 0 }} />
                        </IconButton>
                    </Stack>
                </MenuItem>
                {showImpersonate && (
                    <MenuItem>
                        <ListItemText primary={'Show user view'} />
                        <Switch
                            size='small'
                            checked={impersonate.isImpersonating()}
                            onChange={handleToggleImpersonate}
                            color='success'
                        />
                    </MenuItem>
                )}
                <Divider />
                <MenuItem key='logout' onClick={() => UserService.doLogout()}>
                    Logout
                </MenuItem>
            </Menu>
        </>
    )
}
