import { lazy, Suspense } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { Map } from '..'
import { followPathStateSelector, machineTaskStatus, taskManagerOpenAtom } from '../../_state'
import { TaskManager } from './TaskManager'
import { Stack, Typography, Box, Fab } from '@mui/material'
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted'
import { styles } from '../../styles'
import { CropTask } from '../../gmap/CropTask'
import { RobotIcon } from '../RobotIcon'
import { RobotFOVTriangle } from '../RobotFOVTriangle'
import { MachineInstruction } from '../../machines/MachineInstruction'
import { useTranslation } from 'react-i18next'

const SelectorRemoteIcon = lazy(() => import('../icons/SelectorRemoteIcon'))
const MoveInRangeIcon = lazy(() => import('../icons/MoveInRangeIcon'))

export const DriveTab = () => {
    // console.log('[DriveTab] render')
    const { t } = useTranslation('machines')
    const [taskManagerOpenOpen, setTaskManagerOpen] = useRecoilState(taskManagerOpenAtom)
    const machineTaskState = useRecoilValue(machineTaskStatus)
    const followPathState = useRecoilValue(followPathStateSelector)
    const handleFabClick = () => setTaskManagerOpen(true)

    return (
        <Box sx={[styles.DriveTab.OuterBox]}>
            <Box sx={[styles.DriveTab.MapBox]}>
                <Map>
                    <CropTask />
                    <RobotIcon />
                    {['OUTRANGE', 'INRANGE'].includes(followPathState) && (
                        <RobotFOVTriangle color={followPathState === 'OUTRANGE' ? '#ed6c02' : '#00ff00'} />
                    )}
                </Map>
                {!taskManagerOpenOpen && (
                    <Fab
                        variant='extended'
                        onClick={handleFabClick}
                        disabled={
                            machineTaskState === 'stop' ||
                            machineTaskState === 'paused' ||
                            machineTaskState === 'loading'
                        }
                        sx={[styles.DriveTab.Fab]}
                    >
                        <FormatListBulletedIcon sx={{ m: 1 }} />
                        {taskManagerOpenOpen ? t('machines:DriveTab.close') : t('machines:DriveTab.tasks')}
                    </Fab>
                )}
            </Box>
            {followPathState === 'OUTRANGE' && (
                <MachineInstruction
                    instruction={t('machines:DriveTab.outrangeInstruction')}
                    helpboxBody={
                        <Stack spacing={1}>
                            <Typography variant='body2' fontWeight='400' sx={{ whiteSpace: 'pre-line' }}>
                                {t('machines:DriveTab.outrangeHelp1')}
                            </Typography>
                            <Typography variant='body2' fontWeight='400' sx={{ whiteSpace: 'pre-line' }}>
                                {t('machines:DriveTab.outrangeHelp2')}
                            </Typography>
                            <Suspense fallback={<div>{t('machines:DriveTab.loading')}</div>}>
                                <MoveInRangeIcon />
                            </Suspense>
                        </Stack>
                    }
                    color='#ed6c02'
                />
            )}
            {followPathState === 'INRANGE' && (
                <MachineInstruction
                    instruction={t('machines:DriveTab.inrangeInstruction')}
                    helpboxBody={
                        <Stack spacing={1}>
                            <Typography variant='body2' fontWeight='400' sx={{ whiteSpace: 'pre-line' }}>
                                {t('machines:DriveTab.inrangeHelp')}
                            </Typography>
                            <Suspense fallback={<div>{t('machines:DriveTab.loading')}</div>}>
                                <SelectorRemoteIcon />
                            </Suspense>
                        </Stack>
                    }
                    color={'#0288D1'}
                />
            )}
            {taskManagerOpenOpen && (
                <Box sx={[styles.DriveTab.TaskManagerBox]}>
                    <TaskManager />
                </Box>
            )}
        </Box>
    )
}
