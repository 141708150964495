import { atom } from 'recoil'
import { TaskSettings } from '../types'

export const taskSettingsState = atom<TaskSettings>({
    key: 'taskSettingsState',
    default: {
        bed_extension: 0.0,
        radius: 2.5,
        method: 'planted-area',
        turn: 'three-point',
        direction: 'up',
        repeat: 0,
        pick_up_point: null,
    },
})
